<script>
    import { setContext } from 'svelte';

    /**
     * @type {{
     *  component: Object,
     *  params: Object<string, *>,
     *  resolve: function(*):*,
     *  reject: function(*):*,
     *  context: string
     * }}
     */
    let stack = [];

    /**
     * @param {Object} component
     * @param {Object<string, *>=} params
     * @param {string=} context
     * @return {Promise<*>}
     */
    function push(component, params, context) {
        return new Promise((resolve, reject) => {
            stack.push({
                component,
                params,
                resolve,
                reject, 
                context
            });
            stack = stack;
        });
    }

    let nextContext = 1;

    setContext('modal', {
        /**
         * create a new modal context
         * to be used by a modal itself
         * @return {{push:function(),close:function()}}
         */
        context: function() {
            const context = nextContext++;
            return {
                push: function() {
                    let args;
                    if(arguments.length > 1) {
                        args = [...arguments, context];
                    } else {
                        args = [arguments[0], {}, context];
                    }
                    return push.apply(this, args);
                },
                close: function() {
                    let updated = false;
                    for(let i = stack.length - 1; i >= 0; i--) {
                        if(stack[i].context === context) {
                            stack[i].reject();
                            stack.splice(i, 1);
                            updated = true;
                        }
                    }
                    if(updated) {
                        stack = stack;
                    }
                }
            }
        },
        /**
         * push a new modal
         * to be used from any component that wants to push a modal
         * @return {Promise<*>}
        */
        push
    });

    /**
     * @param {{detail:*}} e
     */
    function cancel(e) {
        const modal = stack.pop() || null;
        if(modal) {
            modal.reject(e.detail);
        }
        stack = stack;
    }

    /**
     * @param {{detail:*}} e
     */
    function success(e) {
        const modal = stack.pop() || null;
        if(modal) {
            modal.resolve(e.detail);
        }
        stack = stack;
    }
</script>

<style>
    .modal-wrapper {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
</style>

{#each stack as modal, index}
    <div class="modal-wrapper" style="z-index: {2000+index}">
        <svelte:component this={modal.component} {...modal.params} on:cancel={cancel} on:success={success} />
    </div>
{/each}
<slot></slot>