<script>
    import { data as siteData } from "../../store/site";
    export const theme = 'bcg';
    export let animated = true;

    let video;

    function updateVideo(state) {
        try {
            if(!state) { 
                video.pause();
            } else {
                video.play();
            }
        } catch(_e) {}
    }
    $: video && updateVideo(animated);

</script>

<style>
    .bg-animation, .bg-animation-grid {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
</style>

{#if $siteData}
    <video bind:this={video} class="bg-animation" src="/assets/Plexus_{$siteData.theme}.mp4" playsinline autoplay muted loop crossorigin="anonymous" />
    <img class="bg-animation-grid" src="/assets/grid_{$siteData.theme}.png" alt="" />
{/if}