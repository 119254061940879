<script>
    export let value = '';
    export let error = false;
    export let mode = 'text';
</script>

<style>
    .code {
        font-size: 40pt;
        padding: 5px;
        height: 1em;
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.3);
        box-shadow: inset 0 4px 10px 0 rgba(0,0,0,.3),0 4px 20px 0 rgba(0,0,0,.1);
        position: relative;
        width: 100%;
        margin: 10px;
    }
    .code.error {
        background-color: rgba(255, 0, 0, 0.3);
        color: red;
    }
    .clear {
        background-color: rgba(255,255,255,0.1);

        float: right;
        margin: 5px;
        text-align: center;
        border-radius: 20px;
        font-size: 10pt;
        padding: 5px;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 4px 10px 0 rgba(0,0,0,.3),0 4px 20px 0 rgba(0,0,0,.1);
        cursor: pointer;
        position: absolute;
        right: 5px;
        top: 2px;
    }
    .code input {
        border: none;
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 12px;
        background: none;
        color: white;
        font-size: 16pt;
        position: absolute;
        top: 0;
        left: 0;
    }
</style>

<div class="code" class:error>
    {#if mode === 'code'}
        <input type="password" bind:value />
    {:else}
        <input type="text" bind:value />
    {/if}
    {#if value}
        <div
            on:click={() => {
                value = "";
                error = false;
            }}
            class="clear"
        >
            X
        </div>
    {/if}
</div>