<script>
    import { getContext, onDestroy } from 'svelte';

    import { library, libraryConfig, view, refresh, clear, uploadFile, downloadObject, deleteObject } from './store/library';

	import OverlaySpinner from './components/widget/OverlaySpinner.svelte';
    import DropTarget from './components/widget/DropTarget.svelte';
    import Rename from './modals/Rename.svelte';
    import CreateFolder from './modals/CreateFolder.svelte';
    import Login from './modals/LibraryLogin.svelte';
    import Confirm from './modals/Confirm.svelte';

    const Modal = getContext('modal').context();
    onDestroy(() => Modal.close());

    let loading = true;
	library.subscribe(value => {
        Modal.close();
        if(!value) {
            Modal.push(Login)
                .catch(() => {
                    /* login cancelled or failed */
                });
		} else {
            loading = false;
        }
	});

    let path = '';
    let currentView = null;
    let progress = 0;

    $: path, (currentView = view(path));

    function open(object) {
        if(typeof object === 'string') {
            path = object;
        } else {
            path = (path ? path + '/' : '') + object.name;
        }
    }

    let showDrop = false;

    async function processEntry(entry) {
        if(entry.isFile) {
            return await new Promise((resolve, reject) => {
                let path = entry.fullPath;
                if(path.startsWith('/')) {
                    path = path.substring(1);
                }
                entry.file(file => resolve({path, file}), () => {
                    reject(new Error('Failed to read file'));
                });
            });
        } else if(entry.isDirectory) {
            return await new Promise((resolve, reject) => {
                const reader = entry.createReader();
                reader.readEntries(async (files) => {
                    files = (await Promise.all(files.map(file => processEntry(file)))).flat();
                    resolve(files);
                }, () => {
                    reject(new Error('Failed to read directory'));
                });
            });
        }
    }

    async function upload(ev) {
        loading = true;
        let files;
        if(ev.target && ev.target.files) {
            files = [];
            for(let i = 0, n = ev.target.files.length; i < n; i++) {
                const file = ev.target.files[i];
                files.push({
                    file,
                    path: file.webkitRelativePath || null
                });
            }
        } else if(ev.dataTransfer && ev.dataTransfer.items) {
            files = [];
            for(let i = 0, n = ev.dataTransfer.items.length; i < n; i++) {
                const entry = ev.dataTransfer.items[i].webkitGetAsEntry();
                files.push(processEntry(entry));
            }
            files = (await Promise.all(files)).flat();
        } else if(ev.dataTransfer && ev.dataTransfer.files) {
            // fallback for older browsers
            files = [];
            for(let i = 0, n = ev.dataTransfer.files.length; i < n; i++) {
                const file = ev.dataTransfer.files[i];
                files.push({
                    file,
                    path: null
                });
            }
        }
        if(files) {
            let fileProgress = [];
            let totalBytes = 0;
            progress = 0;
            await Promise.all(files
                .filter(file => file.file.name.endsWith('.pptx'))
                .map((file, idx) => {
                    let target;
                    if(file.path) {
                        target = file.path;
                    } else {
                        target = file.file.name;
                    }
                    totalBytes += file.file.size;
                    fileProgress[idx] = 0;
                    return uploadFile((path ? path + '/' : '') + target, file.file, (loaded, total) => {
                        fileProgress[idx] = loaded;
                        progress = fileProgress.reduce((total, current) => total + current, 0) / totalBytes;
                    });
                })
            );
        }
        if(ev.target && ev.target.files) {
            ev.target.value = null;
        }
        loading = false;
    }

    let container = null;
    $: container && (() => {
        container.addEventListener('dragover', (evt) => {
            evt.stopPropagation();
            evt.preventDefault();
            evt.dataTransfer.dropEffect = 'copy';
            showDrop = true;
            return false;
        }, false);
        container.addEventListener('dragleave', () => {
            showDrop = false;
            return false;
        }, false);
        container.addEventListener('drop', (evt) => {
            evt.stopPropagation();
            evt.preventDefault();
            showDrop = false;
            upload(evt);
            return false;
        }, false);
    })();

    /**
     * - delete button when sth is selected
     * - folder upload: paths in non-firefox => file api?
     * - drag & drop upload: paths?? file api?
    */
</script>

<style>
    .wrapper {
        position: absolute;
        z-index: 1000;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 10%;
        padding-right: 10%;
        background-color: rgba(0, 0, 0, 0.5);
    }
    .container {
        box-shadow: 2px 2px 4px black;

        position: relative;
        overflow: hidden;
        color: white;
        font-size: 24pt;
        padding: 8px;
        padding-top: 0px;
        border-radius: 8px;
        background: #292a36;
        background: -moz-linear-gradient(top, #292a36 0%, #1b1c25 100%);
        background: -webkit-linear-gradient(top, #292a36 0%, #1b1c25 100%);
        background: linear-gradient(to bottom, #292a36 0%, #1b1c25 100%);
        height: 80px;
        border: 2px solid rgba(150, 150, 150, 0.5);

        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;

        width: calc(100vw - 50px);
        height: calc(100vh - 50px);

        padding: 20px;
    }
    .object {
        width: 100%;
        padding: 8px;
        margin: 3px;
        border-radius: 4px;
        background-color: rgba(255, 255, 255, 0.1);
        display: flex;
        flex-direction: row;
        font-size: 12pt;
        align-items: center;
        justify-content: center;
        box-shadow: 0 4px 10px 0 rgba(0,0,0,.2),0 4px 20px 0 rgba(0,0,0,.1);
    }
    .object:hover {
        background-color: rgba(255, 255, 255, 0.3);
    }
    .object .name {
        flex-grow: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .object .icon {
        flex-grow: 0;
        flex-shrink: 0;
        width: 48px;
        padding-left: 8px;
        display: flex;
    }
    .object .actions {
        flex-grow: 0;
        flex-shrink: 0;
        visibility: hidden;
        padding-left: 10px;
    }
    .object:hover .actions {
        visibility: visible;
    }
    .folder {
        background-color: rgba(255, 255, 255, 0.2);
        cursor: pointer;
    }
    .folder:hover {
        background-color: rgba(255, 255, 255, 0.5);
    }
    /*
    .file {

    }
    */
    .object .date {
        flex-grow: 0;
        flex-shrink: 0;
        width: 150px;
        text-align: right;
    }
    .object .size {
        flex-grow: 0;
        flex-shrink: 0;
        width: 150px;
        text-align: right;
    }
    .crumbs {
        display: flex;
        justify-content: start;
        align-items: center;
        flex-direction: row;
        width: 100%;
        padding: 10px;
        border-radius: 4px;
        background-color: rgba(255,255,255,0.1);
        margin-bottom: 10px;
        font-size: 12pt;
        box-shadow: 0 4px 10px 0 rgba(0,0,0,.2),0 4px 20px 0 rgba(0,0,0,.1);
    }
    .crumb {
        cursor: pointer;
        color: white;
    }
    .separator {
        display: flex;
        opacity: 0.2;
        color: white;
    }
    .menu {
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: center;
        width: 100%;
        margin-bottom: 10px;
        font-size: 12pt;
    }
    .menu div, .menu label {
        margin-right: 10px;
        display: flex;
        cursor: pointer;
        padding: 8px;
        border-radius: 4px;
        color: #bbb;
        border: 2px solid #333;
    }
    .menu div:hover, .menu label:hover {
        background-color: rgba(255,255,255,0.2);
        border: 2px solid #777;
    }
    .menu ion-icon {
        margin-right: 5px;
    }
    .sr-only{position:absolute;width:1px;height:1px;padding:0;overflow:hidden;clip:rect(0,0,0,0);white-space:nowrap;border:0}
</style>

<div class="wrapper">
    <div bind:this={container} class="container">
        {#if loading || (currentView && $currentView.loading)}
            <OverlaySpinner>
                <div slot="caption">
                    {#if progress}
                        {#if progress < 1} 
                            {(progress*100).toFixed(2)}%
                        {:else}
                            Processing...
                        {/if}
                    {/if}
                </div>
            </OverlaySpinner>
        {/if}
        {#if showDrop}
            <DropTarget on:drop={upload}></DropTarget>
        {/if}
        <div class="crumbs">
            <div class="crumb" on:click={() => {
                open("");
            }}>Library '{$libraryConfig && $libraryConfig.name}'</div>
            <div class="separator">
                <ion-icon name="chevron-forward-outline"></ion-icon>
            </div>
            {#each (path ? path.split('/') : []) as part, idx}
                <div class="crumb" on:click={() => {
                    open(path.split('/').slice(0, idx+1).join('/'));
                }}>{part}</div>
                <div class="separator">
                    <ion-icon name="chevron-forward-outline"></ion-icon>
                </div>
            {/each}
        </div>
        <div class="menu">
            <div on:click={() => {
                Modal.push(CreateFolder, {path})
                    .catch(() => {
                        /* login cancelled or failed */
                    });
            }}><ion-icon name="folder-open-outline"></ion-icon>New folder</div>
            <label>
                <ion-icon name="cloud-upload-outline"></ion-icon>Upload Folder
                <input on:change={upload} type="file" class="sr-only" id="folderInput" name="folder" accept=".pptx,application/vnd.openxmlformats-officedocument.presentationml.presentation" webkitdirectory mozdirectory msdirectory odirectory directory allowdirs multiple="multiple">
            </label>
            <label>
                <ion-icon name="cloud-upload-outline"></ion-icon>Upload File
                <input on:change={upload} type="file" class="sr-only" id="fileInput" name="file" accept=".pptx,application/vnd.openxmlformats-officedocument.presentationml.presentation" multiple="multiple">
            </label>
            <div on:click={()=>{
                Modal.push(Confirm, {caption: 'Delete entire content of current folder?'})
                    .then(() => clear(path))
                    .catch(() => {
                        /* confirm cancelled, do nothing */
                    });
            }}><ion-icon name="nuclear-outline"></ion-icon>Clear</div>
            <div on:click={refresh}><ion-icon name="refresh-circle-outline"></ion-icon>Refresh</div>
        </div>
    {#each $currentView.objects as object}
        {#if object.type === 'folder'}
            <div class="object folder" on:click={()=>open(object)}>
                <div class="icon"><ion-icon name="folder-outline"></ion-icon></div>
                <div class="name">{object.name}</div>
                <div class="actions">
                    <ion-icon on:click={(ev)=>{deleteObject((path ? path + '/' : '') + object.name + '/'); ev.stopPropagation(); return false;}} name="trash-outline"></ion-icon>
                    <ion-icon on:click={(ev) => {
                        Modal.push(Rename, {name: object.name, path, type: 'folder'})
                            .catch(() => {
                                /* login cancelled or failed */
                            });
                        ev.stopPropagation(); 
                        return false;
                    }} name="create-outline"></ion-icon>
                </div>
            </div>
        {:else}
            <div class="object file">
                <div class="icon"><ion-icon name="document-outline"></ion-icon></div>
                <div class="name">{object.name}</div>
                <div class="size">{(object.size/(1024*1024)).toFixed(2)}M</div>
                <div class="date">{object.date}</div>
                <div class="actions">
                    <ion-icon on:click={(ev)=>{downloadObject((path ? path + '/' : '') + object.name); ev.stopPropagation(); return false;}} name="cloud-download-outline"></ion-icon>
                    <ion-icon on:click={(ev)=>{deleteObject((path ? path + '/' : '') + object.name); ev.stopPropagation(); return false;}} name="trash-outline"></ion-icon>
                    <ion-icon on:click={(ev) => {
                        Modal.push(Rename, {name: object.name, path, type: 'file'})
                            .catch(() => {
                                /* login cancelled or failed */
                            });
                        ev.stopPropagation(); 
                        return false;
                    }} name="create-outline"></ion-icon>
                </div>
            </div>
        {/if}
    {/each}
    </div>
</div>