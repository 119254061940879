<script>
    // example: https://github.com/amazon-archives/amazon-transcribe-websocket-static/blob/master/lib/main.js
    import { onMount } from 'svelte';
    import { createEventDispatcher } from 'svelte';
    
    export let active = false;

    const dispatch = createEventDispatcher();

    class AudioVisualizer {
        constructor( audioContext, processFrame, processError ) {
            this.doStop = false;
            this.audioContext = audioContext;
            this.processFrame = processFrame;
            this.connectStream = this.connectStream.bind( this );
            navigator.mediaDevices.getUserMedia( { audio: true, video: false } )
            .then( this.connectStream )
            .catch( ( error ) => {
                if ( processError ) {
                processError( error );
                }
            });
        }

        connectStream( stream ) {
            this.analyser = this.audioContext.createAnalyser();
            const source = this.audioContext.createMediaStreamSource( stream );
            source.connect( this.analyser );
            this.analyser.smoothingTimeConstant = 0.5;
            this.analyser.fftSize = 32;

            this.initRenderLoop( this.analyser );
        }

        initRenderLoop() {
            const frequencyData = new Uint8Array( this.analyser.frequencyBinCount );
            const processFrame = this.processFrame || ( () => {} );

            const renderFrame = () => {
                if(this.doStop) return;

                this.analyser.getByteFrequencyData( frequencyData );
                processFrame( frequencyData );

                requestAnimationFrame( renderFrame );
            };
            requestAnimationFrame( renderFrame );
        }

        stop() {
            this.doStop = true;
        }
    }

    let visualMainElement = null;
    let visualizer = null;
    let audioContext = null;
    let error = null;

    const visualValueCount = 16;
    
    onMount(() => {
        return stop;
    });
    
    const stop = () => {
        if(visualizer) {
            visualizer.stop();
            visualizer = null;
        }
        if(audioContext) {
            audioContext.close();
            audioContext = null;
        }
        dispatch('stop');
    };

    const init = () => {
        if(audioContext) return;
    
        // Creating initial DOM elements
        audioContext = new AudioContext();
        
        // Swapping values around for a better visual effect
        const dataMap = { 0: 15, 1: 10, 2: 8, 3: 9, 4: 6, 5: 5, 6: 2, 7: 1, 8: 0, 9: 4, 10: 3, 11: 7, 12: 11, 13: 12, 14: 13, 15: 14 };
        const processFrame = ( data ) => {
            if(!visualMainElement) return;
            let visualElements = visualMainElement.children;
            const values = Object.values( data );
            let i;
            for ( i = 0; i < visualValueCount; ++i ) {
                const value = values[ dataMap[ i ] ] / 255;
                const elmStyles = visualElements[ i ].style;
                elmStyles.transform = `scaleY( ${ value } )`;
                elmStyles.opacity = Math.max( .25, value );
            }
        };

        const processError = () => {
            error = 'Please allow microphone access';
        }

        visualizer = new AudioVisualizer( audioContext, processFrame, processError );
        
        dispatch('start');
    };

    $: active ? init() : stop();

</script>

<style>
    .btn {
        border-radius: 16px;
        background-color: rgba(0,0,0,0.4);
        color: white;
        font-size: 50px;
        padding: 40px;
    }
    .btn ion-icon {
        margin-right: 30px;
        margin-right: 30px;
        transform: scale(2);
    }
    .main {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .main > div {
        display: inline-block;
        width: 30px;
        height: 300px;
        margin: 0 7px;
        background: currentColor;
        transform: scaleY( .5 );
        opacity: .25;
    }
        
    .main.error {
        color: #f7451d;
        min-width: 20em;
        max-width: 30em;
        margin: 0 auto;
        white-space: pre-line;
    }
</style>

{#if !visualizer}
    <div class="btn" on:click={init}>
        <ion-icon name="mic-circle-outline" size="large"></ion-icon>
        Start transcription
    </div>
{:else}
    <div on:click={stop} class="main" class:error={error} bind:this={visualMainElement}>
        {#each Array(visualValueCount) as _, i}
            <div></div>
        {/each}
    </div>
{/if}