<script>
    import Modal from '../components/Modal.svelte';
    import Button from '../components/widget/Button.svelte';
    import Input from '../components/widget/Input.svelte';
    import Spacer from '../components/widget/Spacer.svelte';
    import OverlaySpinner from '../components/widget/OverlaySpinner.svelte';

    import { onDestroy, getContext, createEventDispatcher } from 'svelte';

    const ModalManager = getContext('modal').context();
    onDestroy(() => ModalManager.close());

    import { createFolder } from '../store/library';

    export let path;

    let dispatch = createEventDispatcher();

    let name = '';

    let error = false;
    let loading = false;

    /**
     * submit
    */
    async function submit() {
        try {
            loading = true;
            if(!name) {
                throw new Error('Invalid name');
            }
            await createFolder((path ? path + '/' : '') + name);
            dispatch('success');
        } catch(_e) {
            loading = false;
            error = true;
        }
        /* if there was no error, component will be 'closed'/detached from the outside */
    }

    /**
     * close
     */
     function close() {
        dispatch("cancel");
    }
</script>

<Modal>
    {#if loading}
        <OverlaySpinner></OverlaySpinner>
    {/if}
    <Input bind:name bind:error />
    <Spacer></Spacer>
    <Button on:click={submit}>Create</Button>
    <Spacer></Spacer>
    <Button on:click={close}>Cancel</Button>
</Modal>