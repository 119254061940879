import { client as mqtt } from '../lib/mqtt';
import { get } from 'svelte/store'
import state from './auth';

//-------

/**
 * @param {string} id
 * @param {bool} useRelay
 * @param {bool} persistent
 * @returns {{subscribe:(function(*)):function(),set:function(*)}}
 */
export function makeTransport(id, useRelay = false, persistent = false) {
    const subscriptions = new Set();
    
    let channel, unsubscribe, topic;
    topic = `dbs/${get(state).site}/pod/transport/${id}`;

    if(!useRelay) {
        channel = new BroadcastChannel(id);
        channel.addEventListener('message', function(msg){
            subscriptions.forEach(sub => sub(msg.data));
        });
    } 

    return {
        subscribe: (subscription) => {
            subscriptions.add(subscription);
            
            if(subscriptions.size===1 && useRelay) {
                unsubscribe = mqtt.subscribe(topic, data => {
                    subscriptions.forEach(sub => sub(data));
                });
            }

            return () => {
                subscriptions.delete(subscription);
                if(!subscriptions.size) {
                    if(unsubscribe) {
                        unsubscribe();
                        unsubscribe = null;
                    }
                }
            }
        },
        set: (value) => {
            if(!useRelay) {
                channel.postMessage(value);
            } else {
                mqtt.publish(
                    topic,
                    value,
                    {retain: persistent || false}
                )
            }
            subscriptions.forEach(sub => sub(value));
        }
    }
};


export function makePersistentTransport(id, useRelay = false) {
    const subscriptions = new Set();
    
    let data = makeTransport(id, useRelay, useRelay);
    if(!useRelay) data.set('?');

    let lastValue = null;
    data.subscribe((value) => {
        if(!useRelay && value === '?') {
            if(lastValue) {
                data.set(lastValue);
            }
        } else {
            lastValue = value;
            subscriptions.forEach(sub => sub(lastValue));
        }
    });

    return {
        subscribe: (subscription) => {
            subscriptions.add(subscription);
            if(lastValue) {
                subscription(lastValue);
            }
            return () => {
                subscriptions.delete(subscription);
                if(!subscriptions.size) {
                    if(sub) {
                        sub.unsubscribe();
                        sub = null;
                    }
                }
            }
        },
        set: (value) => {
            data.set(value);
        }
    }
}

// let lastPush = 0;

//data.subscribe((_value) => {
    /*
    if(Date.now() - lastPush > 5000) {
        lastPush = Date.now();

        PubSub.publish(
            'dbs/test/pod/cv',
            value
        );
    }
    */
//});

