<style>
    .grid {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }
</style>

<div class="grid buttons">
    <slot></slot>
</div>