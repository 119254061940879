<script>
	import { setContext } from 'svelte';
	import BGAnimation from './components/widget/BGAnimation.svelte';
	import Controller from './Controller.svelte';
	import Pod from './Pod.svelte';
	import Library from './Library.svelte';
	import Kiosk from './pages/Kiosk.svelte';
	import DemoCV from './pages/demo/CV.svelte';
	import Card from './components/widget/Card.svelte';
	import ModalManager from './components/ModalManager.svelte';
	import OverlaySpinner from './components/widget/OverlaySpinner.svelte';

	import page from './store/page';
    import Catalog from './Catalog.svelte';

	import { Amplify } from 'aws-amplify';

	import config from '../config';
	import { test, signIn } from './lib/authBCG';

	let loading = false;

	if(test()) {
		signIn();
		loading = true;
	} else {
		Amplify.configure({
			Auth: config.aws.auth
		});
	}

	let bgAnimated = true;
	setContext('background', {
        setAnimated: function(animated) {
			bgAnimated = animated;
		}
    });
</script>

<style>
</style>

{#if loading}
	<OverlaySpinner>
		<div slot="caption">Loading</div>
	</OverlaySpinner>
{:else if !$page}
	<div class="wrapper">
		<Card on:click={()=>document.location.href='/catalog'} data={{title:'Digital Asset Library', thumbnailURL:null}}></Card>
	</div>
{:else if $page === 'demo-cv'}
	<DemoCV></DemoCV>
{:else if $page === 'kiosk'}
	<Kiosk></Kiosk>
{:else if $page === 'catalog'}
	<Catalog></Catalog>
{:else if $page.startsWith('pod:')}
	<ModalManager>
		<Pod></Pod>
	</ModalManager>
{:else}
	<BGAnimation animated={bgAnimated}></BGAnimation>
	<ModalManager>
		{#if $page === 'library'}
			<Library></Library>
		{:else}
			<Controller></Controller>
		{/if}
	</ModalManager>
{/if}