<script>
    export let data = [];
    export let width = 31;
    export let index = 0;
    let canvas;

    const links = [
        ["neck", "nose"],
        ["left_eye", "nose"],
        ["right_eye", "nose"],
        ["left_hip", "left_shoulder"],
        ["left_elbow", "left_shoulder"],
        ["left_elbow", "left_wrist"],
        ["left_hip", "left_knee"],
        ["left_knee", "left_ankle"],
        ["right_hip", "right_shoulder"],
        ["right_elbow", "right_shoulder"],
        ["right_elbow", "right_wrist"],
        ["right_hip", "right_knee"],
        ["right_knee", "right_ankle"],
        ["left_shoulder", "right_shoulder"],
        ["left_hip", "right_hip"]
    ];
    const points = [
        "neck", "nose",
        "left_eye", "right_eye",
        "left_hip", "right_hip",
        "left_knee", "right_knee",
        "right_elbow", "left_elbow",
        "left_shoulder", "right_shoulder",
        "left_wrist", "right_wrist",
        "left_ankle", "right_ankle"
    ];

    const COLOR_PALETTE = [
        '#ffffff', '#800000', '#469990', '#e6194b', '#42d4f4', '#fabed4'
    ];

    /**
     * update canvas
     */
    function update() {
        const padding = 5;
        const factorX = (canvas.width-10)/canvas.width;
        const factorY = (canvas.height-10)/canvas.height;
    
        const context = canvas.getContext("2d");
        context.clearRect(0, 0, canvas.width, canvas.height);
        context.strokeStyle = context.fillStyle = data.alert ? 'orange' : 'white';//COLOR_PALETTE[index];
        context.lineWidth = 2;

        const map = {};
        for (let j = 0; j < data.length; j++) {
            const point = data[j];
            map[point.part] = point;
        }

        context.beginPath();
        for (let j = 0; j < links.length; j++) {
            const keyA = links[j][0];
            const keyB = links[j][1];
            const from = map[keyA];
            const to = map[keyB];
            if (!from || !to) continue;
            context.moveTo(padding + (canvas.width - from.x) * factorX, padding + from.y * factorY);
            context.lineTo(padding + (canvas.width - to.x) * factorX, padding + to.y * factorY);
        }
        context.stroke();

        for(let j = 0; j < points.length; j++) {
            let point = points[j];
            if(!map[point]) continue;
            point = map[point];
            context.beginPath();
            context.arc(padding + (canvas.width - point.x) * factorX, padding + point.y * factorY, 4, 0, 2*Math.PI);
            context.fill();
        }
    }

    $: canvas && data && update();
</script>

<div class="wrapper"
    style="flex-basis: {width}"
    >
    <div class="container">
        <div
            class="person"
            class:active={data.length > 0}
            class:alert={data.alert === true}
        >
            <canvas width="150" height="200" bind:this={canvas} />
            <div class="badge">Alert</div>
        </div>
    </div>
</div>

<style>
    .wrapper {
        display: flex;
        justify-content: center;
        flex-basis: 31%;
    }
    .container {
        position: relative;
        width: 100%;
        padding-top: 133%;
    }
    .person {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;

        padding: 5%;
        background-color: rgba(0, 0, 0, 0.4);
    }
    .person.active {
        background-color: rgba(0, 0, 0, 0.8);
    }
    .person.alert {
        background-color: rgba(255, 165, 0, 0.5);
    }
    canvas {
        width: 100%;
        height: 100%;
    }
    .badge {
        display: none;
        color: white;
        text-align: center;
        background-color: rgba(255, 165, 0, 0.4);
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 0;
        padding: 6px;
        font-weight: bold;
        font-size: 30px;
    }
    .alert .badge {
        display: block;
    }
</style>
