<script>
	import Detections from '../../pods/cv/components/Detections.svelte';
	import CameraFeed from '../../pods/cv/components/CameraFeed.svelte';
</script>

<style>
	main {
		background-color: white;
		background-image: url('/assets/background_primary_bcg.png');
        background-size: cover;
		
    }
    .split-v {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 100%;
		justify-content: center;
		align-items: center;
	}
	.split-v>div {
		flex-grow: 0;
		flex-shrink: 0;
		flex-basis: 50%;
	}
	.split-v>div {
		max-height: 50vh;
	}
	.wrapper1 {
        width: calc(50vh * 1.77 - 50px);
        height: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
    }
	.wrapper2 {
		width: 100%;
	/**
		transform: scale(0.8);
		min-width: 1720px;
		*/
	}
</style>

<main>
    <div class="split-v">
        <div class="wrapper1">
			<CameraFeed transport="local" maxDetections={5}></CameraFeed>
        </div>
        <div class="wrapper2">
            <Detections transport="local" maxDetections={5} row={5} showCount={false} showAlert={true}></Detections>
        </div>
    </div>
</main>