<script>
    import { getContext, setContext, onDestroy } from 'svelte';

    import state from './store/auth';
    import { connected } from './lib/mqtt';
	import page from './store/page';

    import pods from './pods';

	import OverlaySpinner from './components/widget/OverlaySpinner.svelte';

    import Login from './modals/SiteLogin.svelte';

    import { publish as publishToChannel, subscribe as subscribeToChannel } from './lib/channel';

    const Modal = getContext('modal').context();
    onDestroy(() => Modal.close());

    let loading = true;
    let loading2 = false;
    let loadingCaption = '';

    let component = null;
    let screen = null;

    function showLogin() {
        Modal.close();
        if(!$state.loading) {
            loading = false;
            if(!$state.user || !$state.site) {
                Modal.push(Login)
                    .then(() => {
                        publishToChannel({action:'refreshLogin'});
                    })
                    .catch(() => {
                        /* login cancelled or failed */
                    })
            }
		}
    }

    $: $state && showLogin();

    subscribeToChannel(event => {
        if(event.action === 'refreshLogin') {
            document.location.reload();
        }
    });

    setContext('loader', {
        setLoading: function(state, caption) {
			loading2 = state;
            loadingCaption = caption || '';
		}
    });

    $: $page && (() => {
        component = null;
        screen = null;
        if($page.startsWith('pod:')) {
            const parts = $page.split(':');
            screen = parts[2];
            const pod = pods.find(pod => pod.id === parts[1]);
            component = pod.components[parts[2]];
            showLogin();
        }
    })();
</script>

<style>
    .caption {
        padding: 10px;
    }
    .bg {
        background-color: white;
        width: 100%;
        height: 100%;
        position: relative;
    }
    .bg.secondary {
        width: 1080px;
        height: 1920px;
    }
    .bg.primary, .bg.control {
        width: 1920px;
        height: 1080px;
    }
</style>

<div class="bg" class:secondary={screen=='secondary'} class:primary={screen=='primary'} class:control={screen=='control'}>
    {#if loading || loading2 || $state && !$state.loading && $state.user && $state.site && !$connected}
        <OverlaySpinner>
            <span slot="caption" class="caption">
                {loading ? 'Authenticating...' : (loadingCaption || 'Connecting...')}
            </span>
        </OverlaySpinner>
    {/if}
    {#if $state.user && $state.site && $connected}
        <svelte:component this={component} />
    {/if}
</div>