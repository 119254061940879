
import { readable } from 'svelte/store';
import config from "../../config.json";

let s3;

let views = [];

let _libraryConfig = null;
let _library = null;
let setLibrary = () => {};
export const library = readable(null, (set) => {
    setLibrary = set;
});

let setLibraryConfig = () => {};
export const libraryConfig = readable(null, (set) => {
    setLibraryConfig = set;
});

/**
 * rename all objects in a folder
 * @param {string} dir 
 */
 async function _renameFolder(dir, newName) {
    if(dir[0] === '/') dir = dir.substr(1);
    if(!dir.endsWith('/')) dir += '/';

    const listParams = {
        Bucket: _library.bucket,
        Prefix: dir
    };

    const listedObjects = await s3.listObjectsV2(listParams).promise();

    if (listedObjects.Contents.length === 0) return;

    let objects = [];
    listedObjects.Contents.forEach(({ Key }) => {
        objects.push({
            new: Key.replace(dir, newName),
            old: Key
        });
    });

    await Promise.all(objects.map(async object => {
        await s3.copyObject({
            Bucket: _library.bucket,
            Key: object.new,
            CopySource: '/' + _library.bucket + '/' + object.old
        }).promise();
        await _deleteObject(object.old);
    }));
}

/**
 * delete all objects in a folder
 * @param {string} dir 
 */
 async function _clearFolder(dir) {
    if(dir[0] === '/') dir = dir.substr(1);
    const listParams = {
        Bucket: _library.bucket,
        Prefix: dir
    };

    const listedObjects = await s3.listObjectsV2(listParams).promise();

    if (listedObjects.Contents.length === 0) return;

    const deleteParams = {
        Bucket: _library.bucket,
        Delete: { Objects: [] }
    };

    listedObjects.Contents.forEach(({ Key }) => {
        // do not delete the dummy folder object if it exists
        if(Key === dir + '/') return;
        deleteParams.Delete.Objects.push({ Key });
    });

    if(!deleteParams.Delete.Objects.length) return;

    await s3.deleteObjects(deleteParams).promise();

    if (listedObjects.IsTruncated) await _clearFolder(dir);
}

/**
 * delete a folder an all contained objects
 * @param {string} dir 
 */
async function _deleteFolder(dir) {
    if(dir[0] === '/') dir = dir.substr(1);
    await _clearFolder(dir);
    await s3.deleteObject({
        Bucket: _library.bucket,
        Key: dir
    }).promise();
}

/**
 * delete an object
 * @param {string} dir 
 */
async function _deleteObject(key) {
    if(key[0] === '/') key = key.substr(1);
    await s3.deleteObject({
        Bucket: _library.bucket,
        Key: key
    }).promise();
}

/**
 * @param {bool} loading 
 */
function _toggleLoading(loading) {
    for(let i = 0, n = views.length; i < n; i++) {
        const view = views[i];
        view.data.loading = loading;
        for(let j = 0, m = view.subs.length; j < m; j++) {
            view.subs[j](view.data);
        }
    }
}

/**
 * clear all library contents
 */
export async function clear(path) {
    _toggleLoading(true);

    await _clearFolder(path);

    // if there was no dummy folder object create one now
    await createFolder(path);

    await refresh();
}

/**
 * delete specified objects
 */
 export async function deleteObjects(objects) {
    for(let i = 0; i < objects.length; i++) {
        await deleteObject(objects[i]);
    }
    await refresh();
}

/**
 * delete specified objects
 */
 export async function deleteObject(path, doRefresh) {
    if(path.endsWith('/')) {
        await _deleteFolder(path);
    } else {
        await _deleteObject(path);
    }
    if(doRefresh || doRefresh === undefined) {
        await refresh();
    }
}

/**
 * delete specified objects
 */
 export async function downloadObject(path, doRefresh) {
    if(path.endsWith('/')) {
        throw new Error('Folders can not be downloaded');
    } else {
        const url = s3.getSignedUrl("getObject", {
            Bucket: _library.bucket,
            Key: path,
        });
        window.open(url);
    }
}

/**
 * rename specified object
 */
 export async function renameObject(path, newPath) {
    if(path === newPath) return;
    if(path.endsWith('/')) {
        await _renameFolder(path, newPath);
    } else {
        await s3.copyObject({
            Bucket: _library.bucket,
            Key: newPath,
            CopySource: '/' + _library.bucket + '/' + path
        }).promise();
        await deleteObject(path);
    }
    await refresh();
}
 
/**
 * refresh library contents
 */
export async function createFolder(path) {
    if(!path.endsWith('/')) path += '/';
    _toggleLoading(true);
    await s3.putObject({
        Bucket: _library.bucket,
        Key: path,
        Body: ''
    }).promise();
    await refresh();
}
 
/**
 * upload a file
 */
 export async function uploadFile(path, blob, cb) {
    _toggleLoading(true);
    const request = s3.putObject({
        Bucket: _library.bucket,
        Key: path,
        Body: blob
    });
    if(cb) {
        request.on('httpUploadProgress', (progress) => {
            cb(progress.loaded, progress.total);
        });
    }
    await request.promise();
    await refresh();
}

/**
 * refresh library contents
 */
export async function refresh() {
    // @TODO: error handling?

    _toggleLoading(true);

    const files = [];

    try {
        const today = (new Date()).toLocaleDateString();
        const data = await s3
            .listObjectsV2({
                Bucket: _library.bucket,
            })
            .promise();
        for (let i = 0, n = data.Contents.length; i < n; i++) {
            const file = data.Contents[i];
            const isFolder = file.Size === 0 && file.Key.endsWith('/')
            if (!(isFolder || file.Key.endsWith(".pptx"))) continue;
            const date = file.LastModified.toLocaleDateString();
            files.push({
                key: file.Key,
                type: isFolder ? 'folder' : 'file',
                size: file.Size,
                date: (date === today ? '' : date + ' ') + file.LastModified.toLocaleTimeString()
            });
        }
    } catch(_e) {
        /* nothing to do here for now */
    }

    for(let i = 0, n = views.length; i < n; i++) {
        const view = views[i];
        view.data.loading = false;
        view.data.objects = [];

        const depth = view.path ? view.path.split('/').length : 0;

        const folders = {};
        for(let j = 0, m = files.length; j < m; j++) {
            const file = files[j];
            if(!file.key.startsWith(view.path)) continue;
            const parts = file.key.split('/');
            if(file.isFolder) parts.pop();

            const name = parts[depth];
            if(!name) continue; // for the folder of the current path (key: "<path>/") the relevant part might be empty
            if(file.type === 'folder' || parts.length > depth + 1) {
                if(!folders[name]) {
                    folders[name] = true;
                    view.data.objects.push({
                        type: 'folder',
                        name: name
                    });
                }
            } else {
                view.data.objects.push({
                    type: 'file',
                    name: name,
                    size: file.size,
                    date: file.date
                });
            }
        }
        view.data.objects.sort((a, b) => {
            if(a.type !== b.type) {
                if(a.type === 'folder') return -1;
                else return 1;
            }
            return a.name.localeCompare(b.name);
        });

        for(let j = 0, m = view.subs.length; j < m; j++) {
            view.subs[j](view.data);
        }
    }
}

/**
 * @param {string} path
 */
export function view(path) {
    if(path[0] === '/') path = path.substring(1);
    let view = views.find(view => view.path === path);
    if(!view) {
        view = {
            path,
            data: {
                loading: true,
                objects: []
            },
            subs: []
        };
        views.push(view);
    }

    refresh();

    return {
        subscribe: function(cb) {
            view.subs.push(cb);
            cb(view.data);
            return () => {
                view.subs.splice(view.subs.indexOf(cb), 1);
                if(view.subs.length === 0) {
                    views.splice(views.indexOf(view), 1);
                }
            };
        }
    }
}

/**
 * @param {string} id
 * @param {string} pin
 */
export async function signIn(id, pin) {
    const library = config.libraries.find(l => l.id === id);
    if(!library) {
        throw new Error('Unknown library id requested');
    }
    const request = await fetch(library.url, {
        method: "POST",
        body: JSON.stringify({
            access_id: library.accessId,
            access_code: pin,
        }),
    });
    const result = await request.json();
    if(!result.library) throw new Error('Invalid API response');
    _library = result.library;
    _libraryConfig = library;
    setLibrary(_library);
    setLibraryConfig(_libraryConfig);

    s3 = new AWS.S3({
        apiVersion: "2006-03-01",
        region: library.region,
        credentials: {
            accessKeyId: _library.secrets.aws_access_key,
            secretAccessKey: _library.secrets.aws_secret_key,
        }
    });

    await refresh();
}

/**
 * 
 */
export async function signOut() {
    setLibrary(null);
    s3 = null;
}