<script>
    import Modal from '../components/Modal.svelte';
    import Button from '../components/widget/Button.svelte';
    import Keypad from '../components/widget/Keypad.svelte';
    import Spacer from '../components/widget/Spacer.svelte';
    import ButtonStrip from '../components/widget/ButtonStrip.svelte';
    import Input from '../components/widget/Input.svelte';
    import config from "../../config.json";
    import OverlaySpinner from "../components/widget/OverlaySpinner.svelte";
    import { signIn } from "../store/library";
    import { onMount, onDestroy, createEventDispatcher, getContext } from "svelte";
    import parseQuery from '../lib/parseQuery';

    const ModalManager = getContext("modal").context();
    onDestroy(() => ModalManager.close());

    let dispatch = createEventDispatcher();

    let activeLibrary = config.libraries[0].id;
    let activeCode = "";
    let error = false;
    let showLibrarySelector = true;

    let loading = false;

    onMount(() => {
        const query = parseQuery(document.location.search);
        if(query && query.id) {
            const library = config.libraries.find(library => library.id === query.id);
            if(library) {
                activeLibrary = query.id;
                showLibrarySelector = false;
            }
        }
    });

    /**
     * select a library
     * @param {string} id
     */
    function selectLibrary(id) {
        activeLibrary = id;
    }

     /**
     * download library
     */
    async function submit() {
        try {
            loading = true;
            await signIn(activeLibrary, activeCode);
            dispatch("success");
        } catch (_e) {
            error = true;
            loading = false;
        }
        /* if there was no error, component will be 'closed'/detached from the outside */
    }
</script>

<Modal>
    {#if loading}
        <OverlaySpinner />
    {/if}
    {#if showLibrarySelector}
        <ButtonStrip>
            {#each config.libraries as library}
                <Button
                    on:click={() => selectLibrary(library.id)}
                    active={activeLibrary == library.id}
                >
                    {library.name}
                </Button>
            {/each}
        </ButtonStrip>
        <Spacer></Spacer>
    {/if}
    <Input mode="code" bind:value={activeCode} bind:error/>
    <Keypad bind:error on:input={(digit)=>{activeCode+=digit.detail;error=false}} />
    <Spacer></Spacer>
    <Button on:click={submit}>Login</Button>
</Modal>