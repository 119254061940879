<script>
    import { data } from '../../store/content';

    export let visible = false;
    let url;
    function updateIFrame() {
        url = $data && $data.url || null;
    }
    $: $data, updateIFrame();
</script>

<style>
    iframe {
        width: 100%;
        height: 100%;
        border:none;
        position: absolute;
        left: 60px;
        width: calc(100vw - 120px);
        top: 60px;
        height: calc(100vh - 120px - 150px);
        border-radius: 16px;
        background-color: white;
    }
    .button {
        color: var(--theme-main);
        flex-grow: 0;
        padding: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 48px;
        height: 48px;
        transition: transform .2s ease-in-out;
        cursor: pointer;
    }
    .button.close {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 101;
    }
    .box {
        background-color: rgba(255, 255, 255,0.85);
        box-shadow: 0 2px 40px 0 rgb(0 0 0 / 10%);
        border: 1px solid #dedede;
        border-radius: 4px;
    }
    .dim {
        position: absolute;
        z-index: 1000;
        background-color: rgba(0,0,0,0.7);
        height: calc(100vh - 150px);
        width: 100vw;
    }
</style>

{#if url && visible}
<div class="dim" on:click={()=>visible=false}>
    <iframe scrolling="no" class="webcontent" src={url} title="Web Content" sandbox="allow-forms allow-same-origin allow-orientation-lock allow-pointer-lock allow-presentation allow-scripts" allow="autoplay *; geolocation *; gyroscope *; accelerometer *; microphone *; camera *; screen-wake-lock *"></iframe>
    <div title="Close Web Content" class="button close box" on:click={()=>visible=false}>
        <ion-icon name="close-outline" size="large"></ion-icon>
    </div>
</div>
{/if}
