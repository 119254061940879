import config from '../../config.json';

let auth = null;
let _session = null;
let setIdToken = null;
let setAccessToken = null;

/**
 * @param {string} queryString
 * @return {Object<string, *>}
 */
export function parseQueryString( queryString ) {
    var params = {}, queries, temp, i, l;
    // Split into key/value pairs
    queries = queryString.split("&");
    // Convert the array of strings into an object
    for ( i = 0, l = queries.length; i < l; i++ ) {
        temp = queries[i].split('=');
        if(temp.length != 2) continue;
        if(!i && temp[0][0] === '?') temp[0] = temp[0].substr(1);
        params[temp[0]] = temp[1];
    }
    return params;
}

/**
 *
 */
export async function signOut() {
    if(!auth) return;
    auth.setState(btoa(JSON.stringify({target:document.location.pathname + document.location.search})));
    auth.signOut();
}

/**
 * @return {?string}
 */
export function idToken() {
    if(_session) {
        return _session.idToken.jwtToken;
    }
    return null;
}

/**
 * @return {?{id:string,clientId:string,name:string}}
 */
export function user() {
    if(_session) {
        return {
            id: _session.idToken.payload['cognito:username'], //_session.idToken.payload.sub,
            clientId: auth.clientId,
            name: (_session.idToken.payload.given_name && _session.idToken.payload.family_name) ?
                `${_session.idToken.payload.given_name} ${_session.idToken.payload.family_name}` :
                (_session.idToken.payload.given_name || 'Anonymous')
        };
    }
    return null;
}

/**
 * @return {?string}
 */
export function refreshToken() {
    if(_session) {
        return _session.getRefreshToken().getToken();
    }
    return null;
}

/**
 * @return {Promise<?string>}
 */
export async function accessToken() {
    if(_session) {
        // if token is expired trigger a refresh & wait for new token
        const delay = (_session.getIdToken().getExpiration() * 1000 - Date.now()) - 60 * 1000;
        if(delay < 0) {
            const promise = new Promise((resolve, reject) => {
                setAccessToken = (token) => {
                    if(token) resolve(token);
                    else reject(new Error('Failed to refresh token'));
                };
            });
            auth.refreshSession(_session.getRefreshToken().getToken());
            await promise;
        }
        return _session.accessToken.jwtToken;
    }
    return null;
}

/**
 * @param {AmazonCognitoIdentity.CognitoUserSession} session
 */
function scheduleRefresh(session) {
    if(refreshTimeout) {
        clearTimeout(refreshTimeout);
    }
    const delay = (session.getIdToken().getExpiration() * 1000 - Date.now()) - 60 * 1000;
    console.info(`[Auth] Scheduled refresh in ${delay/1000} seconds`);
    refreshTimeout = setTimeout(() => {
        console.info(`[Auth] Refreshing tokens`);
        auth.refreshSession(session.getRefreshToken().getToken());
    }, delay);
}

let refreshTimeout = null;

/**
 * @return {bool}
 */
export function test() {
    const query = parseQueryString(document.location.search);
    if(query && query.code && query.state) {
        return true;
    }
    return false;
}

/**
 * @return {Promise}
 */
export function signIn() {
    return new Promise((resolve, reject) => {
        if(auth) {
            resolve();
            return;
        }

        const query = parseQueryString(document.location.search);

        // dynamically determine redirect uri (hast to be allowed in Cognito UserPool!)
        let uri = `${document.location.protocol}//${document.location.host}`;

        const authData = {
            ClientId : config.aws.authBCG.clientId,
            IdentityProvider: 'bcg-okta',
            AppWebDomain : config.aws.authBCG.domain,
            TokenScopesArray : ['email', 'openid', 'profile'],
            RedirectUriSignIn : uri,
            RedirectUriSignOut : uri
        };
        auth = new AmazonCognitoIdentity.CognitoAuth(authData);
        auth.userhandler = {
            onSuccess: result => {
                _session = result;
                scheduleRefresh(result);
                if(setIdToken) {
                    setIdToken(_session.getIdToken());
                    setIdToken = null;
                }
                if(setAccessToken) {
                    setAccessToken(_session.getAccessToken());
                    setAccessToken = null;
                }

                if(query && query.state) {
                    try {
                        let state = atob(decodeURIComponent(query.state));
                        state = JSON.parse(state);
                        if(state.target) {
                            document.location.href = state.target;
                        }
                    } catch(_err) { /** nothing to do here */}
                }

                resolve();
            },
            onFailure: () => {
                if(setIdToken) {
                    setIdToken(null);
                    setIdToken = null;
                }
                if(setAccessToken) {
                    setAccessToken(null);
                    setAccessToken = null;
                }
                auth.signOut();
            }
        };

        // The default response_type is "token", the next line will make it be "code".
        auth.useCodeGrantFlow();

        let response = false;

        if(query && query.code) {
            try {
                response = auth.parseCognitoWebResponse(window.location.href);
            } catch(e) {
                console.error(e);
            }
        }
        if(response === false) {
            if(auth.getCurrentUser()) {
                auth.getSession();
            } else {
                reject('Not authenticated');
            }
        } else {
            history.replaceState(null, "", "/");
        }
    });
}

/**
 *
 */
export function signInInteractive() {
    auth.setState(btoa(JSON.stringify({target:document.location.pathname + document.location.search})));
    auth.getSession();
}