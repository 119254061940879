<script>
    export let warning = false;
    export let active = false;
    export let width = null;
</script>

<style>
    .btn {
        padding: 5px;
        box-shadow: 0 4px 10px 0 rgba(0,0,0,.3),0 4px 20px 0 rgba(0,0,0,.1);
        text-align: center;
        border-radius: 4px;
        background-color: rgba(255,255,255,0.1);
        cursor: pointer;
        width: 100%;

        margin-top: 5px;
        margin-bottom: 5px;
    }
    .btn:hover {
        background-color: rgba(255,255,255,0.2);
    }
    .btn.active {
        background-color: rgba(255, 255, 255, 0.3);
    }
    .btn.warning {
        background-color: rgba(255, 150, 150, 0.3);
    }
    .btn.warning:hover {
        background-color: rgba(255, 150, 150, 0.5);
    }
    .btn:not(:last-child) {
        margin-right: 5px;
    }
    .btn:not(:first-child) {
        margin-left: 5px;
    }
    .btn:only-child {
        margin-left: 0px;
        margin-right: 0px;
    }
</style>

<div class="btn" style="{width ? `width: ${width}` : ''}" class:warning class:active on:click>
    <slot></slot>
</div>