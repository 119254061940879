<script>
    import Modal from '../components/Modal.svelte';
    import Button from '../components/widget/Button.svelte';
    import Spacer from '../components/widget/Spacer.svelte';

    import { onDestroy, getContext, createEventDispatcher } from 'svelte';

    const ModalManager = getContext('modal').context();
    onDestroy(() => ModalManager.close());

    const dispatch = createEventDispatcher();

    export let caption = "Please confirm";
    export let confirm = "Confirm";
    export let cancel = "Cancel";
</script>

<style>
    .caption {
        text-align: center;
        padding: 20px;
    }
</style>

<Modal>
    <div class="caption">{caption}</div>
    <Spacer></Spacer>
    <Button on:click={()=>dispatch("success")}>{confirm}</Button>
    <Spacer></Spacer>
    <Button on:click={()=>dispatch("cancel")}>{cancel}</Button>
</Modal>