<script>
    import Modal from '../components/Modal.svelte';
    import Button from '../components/widget/Button.svelte';
    import Keypad from '../components/widget/Keypad.svelte';
    import Spacer from '../components/widget/Spacer.svelte';
    import ButtonStrip from '../components/widget/ButtonStrip.svelte';
    import Input from '../components/widget/Input.svelte';
    import config from "../../config.json";
    import OverlaySpinner from "../components/widget/OverlaySpinner.svelte";

    import { download, clear } from "../store/content";
    import { onDestroy, createEventDispatcher, getContext } from "svelte";
    import { get } from "svelte/store";
    import state from "../store/auth";

    const ModalManager = getContext('modal').context();
    onDestroy(() => ModalManager.close());

    const site = get(state).site || null;
    const siteLibraries = config.libraries.filter(l => l.sites.includes(site));

    let dispatch = createEventDispatcher();

    let activeLibrary = siteLibraries[0].id;
    let activeCode = "";
    let error = false;

    let loading = false;

    /**
     * select a library
     * @param {string} id
     */
    function selectLibrary(id) {
        activeLibrary = id;
    }

    /**
     * clear library
     */
    async function submitClear() {
        loading = true;
        clear();
        await new Promise((resolve) => {
            setTimeout(() => {
                loading = false;
                resolve();
            }, 5000);
        });
        dispatch("success");
    }

     /**
     * download library
     */
    async function submit() {
        try {
            loading = true;
            const library = siteLibraries.find(
                (l) => l.id === activeLibrary
            );
            const request = await fetch(library.url, {
                method: "POST",
                body: JSON.stringify({
                    access_id: library.accessId,
                    access_code: activeCode,
                }),
            });
            const result = await request.json();

            const s3 = new AWS.S3({
                apiVersion: "2006-03-01",
                region: library.region,
                credentials: {
                    accessKeyId: result.library.secrets.aws_access_key,
                    secretAccessKey: result.library.secrets.aws_secret_key,
                },
            });
            const data = await s3
                .listObjectsV2({
                    Bucket: result.library.bucket,
                })
                .promise();
            const files = [];
            for (let i = 0, n = data.Contents.length; i < n; i++) {
                const file = data.Contents[i];
                if (!file.Key.endsWith(".pptx")) continue;
                files.push({
                    url: s3.getSignedUrl("getObject", {
                        Bucket: result.library.bucket,
                        Key: file.Key,
                        Expires: 3600
                    }),
                    path: file.Key,
                    size: file.Size,
                });
            }

            await Promise.all([
                download(files),
                // wait a few seconds to let the screen state reach
                new Promise((resolve) => {
                    setTimeout(() => {
                        loading = false;
                        resolve();
                    }, 5000);
                }),
            ]);
            dispatch("success");
        } catch (_e) {
            error = true;
            loading = false;
        }
        /* if there was no error, component will be 'closed'/detached from the outside */
    }

    /**
     * close
     */
    function close() {
        dispatch("cancel");
    }
</script>

<Modal>
    {#if loading}
        <OverlaySpinner />
    {/if}
    {#if siteLibraries.length > 1}
        <ButtonStrip>
            {#each siteLibraries as library}
                <Button
                    on:click={() => selectLibrary(library.id)}
                    active={activeLibrary == library.id}>
                    {library.name}
                </Button>
            {/each}
        </ButtonStrip>
        <Spacer></Spacer>
    {/if}
    <Input mode="code" bind:value={activeCode} />
    <Keypad bind:error on:input={(digit)=>{activeCode+=digit.detail;error=false}} />
    <Spacer></Spacer>
    <Button on:click={submit}>Download library</Button>
    <Spacer></Spacer>
    <Button warning={true} on:click={submitClear}>Clear local files</Button>
    <Spacer></Spacer>
    <Button on:click={close}>Cancel</Button>
</Modal>
