<script>
    import Button from './Button.svelte';
    import { createEventDispatcher } from 'svelte';

    const dispatch = createEventDispatcher();
</script>

<style>
    .grid {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        width: 100%;
    }
    .line {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
    }
    .line:last-child {
        justify-content: center;
    }
</style>

<div class="grid">
    <div class="line">
        <Button on:click={() => dispatch('input', 1)} width="30%">1</Button>
        <Button on:click={() => dispatch('input', 2)} width="30%">2</Button>
        <Button on:click={() => dispatch('input', 3)} width="30%">3</Button>
    </div>
    <div class="line">
        <Button on:click={() => dispatch('input', 4)} width="30%">4</Button>
        <Button on:click={() => dispatch('input', 5)} width="30%">5</Button>
        <Button on:click={() => dispatch('input', 6)} width="30%">6</Button>
    </div>
    <div class="line">
        <Button on:click={() => dispatch('input', 7)} width="30%">7</Button>
        <Button on:click={() => dispatch('input', 8)} width="30%">8</Button>
        <Button on:click={() => dispatch('input', 9)} width="30%">9</Button>
    </div>
    <div class="line">
        <Button on:click={() => dispatch('input', 0)} width="30%">0</Button>
    </div>
</div>