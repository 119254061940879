import { readable } from 'svelte/store';
import { Auth, Hub } from 'aws-amplify';

let stateData = {
    user: null,
    site: null,
    credentials: null,
    loading: true
};
let setState = () => {};

export default readable(stateData, (set) => {
    setState = set;
});

let _refresh = null;

function scheduleRefresh() {
    if(refreshTimeout) {
        clearTimeout(refreshTimeout);
    }
    const delay = +stateData.credentials.expiration - 3 * 60 * 1000 - Date.now();
    console.log('Scheduling proactive token refresh in ' + Math.round(delay/1000/60) + ' minutes');
    refreshTimeout = setTimeout(refresh, delay);
}

export async function refresh() {
    if(_refresh) return;
 
    try {
        console.log('Refreshing credentials');
        _refresh = Auth.currentUserCredentials();
        const credentials = await _refresh;
        if(credentials.expiration !== stateData.credentials.expiration) {
            console.log('Tokens refreshed successfully');
            stateData.credentials = await Auth.currentUserCredentials();
            scheduleRefresh();
            setState(stateData);
        }
    } catch(_e) {
        console.log('Failed to refresh credentials, signing out');
        try {
            await Auth.signOut();
        } catch(_e) {
            console.log('Failed to signout, reloading page to reinitialize');
            document.location.reload();
        }
    } finally {
        _refresh = null;
    }
}

export async function signIn(username, password) {
    try {
        await Auth.signIn(username, password);
    } catch(_e) {
        throw _e;
    }
}

let refreshTimeout = null;

Hub.listen('auth', async (data) => {
    switch(data.payload.event) {
        case 'configured':
        case 'signIn':
        case 'signOut':
            try {
                const user = await Auth.currentAuthenticatedUser();
                stateData.user = user;
                stateData.site = user.attributes['custom:site_id'] || null;
                stateData.credentials = await Auth.currentUserCredentials();
                
                console.log("Logged in with identityId", stateData.credentials.identityId);
                
                scheduleRefresh();
            } catch(_e) {
                if(refreshTimeout) {
                    clearTimeout(refreshTimeout);
                }
                console.log("Logged out");
                stateData.user = null;
                stateData.site = null;
                stateData.credentials = null;
            }
            stateData.loading = false;
            setState(stateData);
            break;
    }
    /*
    switch (data.payload.event) {
        case 'signIn':
            logger.info('user signed in');
            break;
        case 'signUp':
            logger.info('user signed up');
            break;
        case 'signOut':
            logger.info('user signed out');
            break;
        case 'signIn_failure':
            logger.error('user sign in failed');
            break;
        case 'tokenRefresh':
            logger.info('token refresh succeeded');
            break;
        case 'tokenRefresh_failure':
            logger.error('token refresh failed');
            break;
        case 'configured':
            logger.info('the Auth module is configured');
    }
    */
});