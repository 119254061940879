<script>
    import { makePersistentTransport, makeTransport } from '../../store/transport.js';
    import { onMount } from 'svelte';
    import { data as siteData } from "../../store/site";
    import pods from "../../store/pods";
    import { detectTargetedSentiment } from './components/comprehend';
    import { each } from 'svelte/internal';

    let entities = [];
    let transport = makeTransport('pod:nlp:demo-targeted-sentiment:data', true);
    transport.subscribe(async (value) => {
        if(value.active) {
            if(entities.length > 0) entities.length = 0;
            transcribedText = value.final && value.final.replace(/\n/g, '<br />') || '';
            if(value.partial) {
                transcribedText += '<span style="color:blue">';
                transcribedText += value.partial.replace(/\n/g, '<br />')|| '';
                transcribedText += '</span>';
            }
        } else {
            const text = value.final + ' ' + value.partial;
            const result = await detectTargetedSentiment(text);
            
            entities = [];
            result.Entities.forEach(entity => {
                const entry = {
                    name: entity.Mentions[entity.DescriptiveMentionIndex].Text,
                    type: entity.Mentions[entity.DescriptiveMentionIndex].Type,
                    sentiment: null,
                    mentions: null
                };
                if(entity.Mentions.length > 1) {
                    entry.mentions = entity.Mentions.map(mention => {
                        return {
                            sentiment: mention.MentionSentiment.Sentiment,
                            text: mention.Text
                        };
                    });
                } else {
                    entry.sentiment = entity.Mentions[0].MentionSentiment.Sentiment;
                }
                entities.push(entry);
            });

            const mentions = result.Entities.map(element => element.Mentions).flat().sort((a,b) => a.BeginOffset - b.BeginOffset);
            let coloredText = '';
            let index = 0;
            for(let i = 0, n = mentions.length; i < n; i++) {
                const mention = mentions[i];

                let color = 'lightblue';
                if(mention.MentionSentiment.Sentiment === 'POSITIVE') color = 'green';
                else if(mention.MentionSentiment.Sentiment === 'NEGATIVE') color = 'red';

                coloredText += text.substring(index, mention.BeginOffset);
                coloredText += '<span style="font-weight:bold;color:' + color + '">' + mention.Text + '</span>';
                index = mention.EndOffset;
            }
            coloredText += text.substring(index);
            coloredText = coloredText.replace(/\n/g, '<br />')|| ''
            transcribedText = coloredText;
/*
            })..forEach(mention => {
                    let color = 'gray';
                    if(mention.MentionSentiment.Sentiment === 'POSITIVE') color = 'green';
                    else if(mention.MentionSentiment.Sentiment === 'NEGATIVE') color = 'red';
                    transcribedText = transcribedText.substring(0, mention.BeginOffset - 1) +
                        '<span style="' + color + '">' + mention.Text + '</span>' +
                        transcribedText.substring(mention.EndOffset);
                });
            });
            */
        }
    });

    let podState = 'idle';
    let podList = [];
    let url = null;
    let iframeSize = 'default';
    let description = '';

    let transcribedText = '';

    function updatePod() {
        const pod = podList.find(pod => podState === 'demo-api-' + pod.id);
        url = pod && pod.url && pod.url.secondary || null;
        iframeSize = pod && pod.size || 'default';
        description = pod && pod.description || podState;

        if(podState === 'demo-targeted-sentiment') {
            transcribedText = '';
            entities.length = 0;
        }
    }

    let data = makePersistentTransport('pod:nlp:ctrl', true);
    onMount(() => {
        const unbindState = data.subscribe((_state) => {
            podState = _state;
            updatePod();
        });
        const unbindPods = pods('nlp').subscribe((_pods) => {
            podList = _pods;
            updatePod();
        });
        return () => {
            unbindState();
            unbindPods();
        }
    });
</script>

<style>
    .video {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .wrapper {
        width: 100%;
        height: 100%;
        background-size: cover;
        padding-top: 275px;
    }
    .wrapper.cmb {
        background-image: url('/assets/background_secondary_cmb.png');
    }
    .wrapper.aws {
        background-image: url('/assets/background_secondary_aws.png');
    }
    .wrapper.bcg {
        background-image: url('/assets/background_secondary_bcg.png');
    }
    iframe {
        width: 100%;
        height: 100%;
        border:none;
        position: absolute;
        top: 0px;
    }
    iframe.default {
        left: 60px;
        width: 960px;
        top: 360px;
        height: 1500px;
    }
    iframe.no-header {
        left: 60px;
        width: 960px;
        top: 60px;
        height: 1800px;
    }
    iframe.fullscreen {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }

    section {
    position: absolute;
    left: 60px;
    top: 40px;
    width: 950px;
    }

    section img {
        position: absolute;
        left: 4px;
        top: 27px;
    }

    section h1 {
    position: absolute;
    font-family: "Suisse Int'l";
    font-weight: bold;
    font-size: 62px;
    top: 135px;
    left: 4px;
    margin-top: 0px;
    margin-bottom: 0px;
    }

    section hr {
    position: absolute;
    background-color:rgb(0, 0, 0);
    color:rgb(0, 0, 0);
    border:rgb(0, 0, 0);
    height:4px;
    width: 960px;
    top: 229px;
    margin-top: 0px;
    margin-bottom: 0px;
    }
    section h2 {
  position: absolute;
  font-family: "Suisse Int'l";
  font-weight: bold;
  font-size: 31px;
  top: 247px;
  left: 4px;
  margin-top: 0px;
  margin-bottom: 0px;
}
.transcribed {
    position: absolute;
    left: 60px;
    width: 960px;
    top: 360px;
    height: 420px;
    font-size: 50px;
    background-color: rgba(0,0,0,0.4);
    color: white;
    padding: 30px;
    overflow: hidden;
}
.analytics {
    position: absolute;
    left: 60px;
    width: 960px;
    top: 850px;
    height: 1020px;
    font-size: 40px;
    background-color: rgba(0,0,0,0.4);
    color: white;
    padding: 30px;
    overflow: hidden;
}
.entity {
    display: flex;
    justify-content: end;
}
.group:not(:first-child) {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px dashed lightgray;
}
.entity.mention div:first-child {
    flex-basis: 30%;
}
.entity div {
    display: flex;
    align-items: center;
    flex-basis: 33%;
    flex-grow: 0;
    overflow: hidden;
}
.entity .muted {
    opacity: 50%;
}
.sentiment {
    color: lightblue;
}
.positive {
    color: green;
}
.negative {
    color: red;
}
</style>

{#if podState === 'idle'}
    <video src="/assets/pods/nlp/{$siteData.theme}_idle_poster.mp4" autoPlay loop muted class="video" crossorigin="anonymous" />
{:else}
    <div class="wrapper {$siteData.theme}">
        {#if podState === 'demo-targeted-sentiment'}
            <section>
                <img src="/assets/{$siteData.theme}_logo.svg">
                <h1 class="title">Natural Language Processing</h1>
                <hr />
                <h2 class="title">Targeted Sentiment</h2>
            </section>
            <div class="transcribed">
                {#if transcribedText}
                    {@html transcribedText}
                {/if}
            </div>
            <div class="analytics">
                {#each entities as entity} 
                    <div class="group">
                        <div class="entity">
                            <div>
                                {entity.name}
                            </div>
                            <div class="muted">
                                {entity.type}
                            </div>
                            <div class="sentiment" class:positive={entity.sentiment==='POSITIVE'} class:negative={entity.sentiment==='NEGATIVE'} >
                                {#if entity.sentiment}
                                    {entity.sentiment}
                                {/if}
                            </div>
                        </div>
                        {#if entity.mentions}
                            {#each entity.mentions as mention}
                                <div class="entity" class:mention={!!entity.mentions}>
                                    <div>
                                        <ion-icon class="muted" name="chevron-forward-outline"></ion-icon> {mention.text}
                                    </div>
                                    <div>&nbsp;</div>
                                    <div class="sentiment" class:positive={mention.sentiment==='POSITIVE'} class:negative={mention.sentiment==='NEGATIVE'} >
                                        {#if mention.sentiment}
                                            {mention.sentiment}
                                        {/if}
                                    </div>
                                </div>
                            {/each}
                        {/if}
                    </div>
                {/each}
            </div>
        {:else if url}
            {#if iframeSize === 'default'}
                <section>
                    <img src="/assets/{$siteData.theme}_logo.svg">
                    <h1 class="title">Natural Language Processing</h1>
                    <hr />
                    <h2 class="title">{description}</h2>
                </section>
            {/if}
            <iframe scrolling="no" class={iframeSize} src={url} title="Demo Content" sandbox="allow-forms allow-same-origin allow-orientation-lock allow-pointer-lock allow-presentation allow-scripts" allow="autoplay *; geolocation *; gyroscope *; accelerometer *; microphone *; camera *; screen-wake-lock *"></iframe>
        {/if}       
    </div>
{/if}