import { writable } from 'svelte/store';
import pods from '../pods';

let page = null;
const parts = document.location.pathname.split('/');
parts.shift();

if(parts[0] === 'demo' && parts[1] === 'cv') {
    page = 'demo-cv';
} else if(parts[0] === 'cmd') {
    page = 'cmd';
} else if(parts[0] === 'model') {
    page = 'model';
} else if(parts[0] === 'kiosk') {
    page = 'kiosk';
} else if(parts[0] === 'library') {
    page = 'library';
} else if(parts[0] === 'catalog') {
    page = 'catalog';
} else if(parts[0] === 'pod' && parts.length === 3) {
    const pod = pods.find(pod => pod.id == parts[1]);
    if(pod && pod.components[parts[2]]) {
        page = `${parts[0]}:${parts[1]}:${parts[2]}`;
    }
}

export default writable(page);