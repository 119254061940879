import state from '../store/auth';
import { refresh } from '../store/auth';
import config from '../../config.json';
import v4 from 'aws-signature-v4';
import { readable } from 'svelte/store';

let authState = null
state.subscribe(_state => authState = _state);

const transformWsUrl = (url, _options, _client) => {
    if(!authState || !authState.credentials) {
        console.error('MQTT: Not authenticated, aborting');
        return url;
    }
    if(+authState.credentials.expiration - Date.now() <= 5 * 60) {
        refresh()
            .catch(() => { /* nothing to do here */ });
        console.error('MQTT: credentials (almost) expired, connection will likely fail');
    }
    const result = v4.createPresignedURL(
        'GET',
        url.match(/wss:\/\/(.*?)(:\d+){0,1}\/mqtt/)[1],
        '/mqtt',
        'iotdevicegateway',
        '',
        {
          key: authState.credentials.accessKeyId,
          secret: authState.credentials.secretAccessKey,
          protocol: 'wss',
          region: config.aws.iotCore.aws_pubsub_region
        }
      );
    return result+'&X-Amz-Security-Token='+encodeURIComponent(authState.credentials.sessionToken);
}

let mqttClient = null;

const subscriptions = new Map();

function connect() {
    if(mqttClient) return;
    mqttClient = mqtt.connect(config.aws.iotCore.aws_pubsub_endpoint, {
        protocolId: 'MQIsdp',
        protocolVersion: 3,
        transformWsUrl: transformWsUrl
    });
    mqttClient.on('connect', () => setState(true));
    mqttClient.on('message', function (topic, message) {
        message = message.toString();
        try {
            message = JSON.parse(message);
        }catch(_e) {
            /** nothing to do here */
        }
    
        const cbs = subscriptions.get(topic);
        if(!cbs) return;
        cbs.forEach(cb => cb(message));
    });
}

let setState = (value) => { _connected = value; };
let _connected = false;
export const connected = readable(false, (set) => {
    setState = (v) => {
        // add a short delay before changing state to allow retained messages to arrive
        setTimeout(() => set(v), 1000);
    };
    set(_connected);
});

export const client = {
    subscribe: (topic, cb) => {
        connect();
        
        if(!subscriptions.has(topic)) {
            subscriptions.set(topic, new Set([cb]));
            mqttClient.subscribe(topic, function (err) {
                /** handle this?! */
            });
        } else {
            const cbs = subscriptions.get(topic);
            cbs.add(cb);
        }
        
        return () => {
            const cbs = subscriptions.get(topic);
            cbs.delete(cb);
        };
    },
    publish: (topic, message, options) => {
        connect();
        if(typeof message === 'object') {
            message = JSON.stringify(message);
        }
        mqttClient.publish(topic, message, options);
    }
};