<script>
    import Modal from '../components/Modal.svelte';
    import Button from '../components/widget/Button.svelte';
    import Spacer from '../components/widget/Spacer.svelte';
    import OverlaySpinner from "../components/widget/OverlaySpinner.svelte";
    import { createEventDispatcher, onDestroy, getContext } from "svelte";
    import { data as siteData, selectTheme } from "../store/site";

    const ModalManager = getContext('modal').context();
    onDestroy(() => ModalManager.close());

    let dispatch = createEventDispatcher();
    let loading = false;

    /**
     * close
     */
    function close() {
        dispatch("cancel");
    }
</script>

<Modal>
    {#if loading}
        <OverlaySpinner />
    {/if}
    {#each $siteData.themes as theme}
        <Button on:click={()=>selectTheme(theme)}>{theme}</Button>
    {/each}
    <Spacer></Spacer>
    <Button on:click={close}>Close</Button>
</Modal>
