import state from '../../../store/auth';
import config from '../../../../config.json';

let authState = null
state.subscribe(_state => authState = _state);

export function detectTargetedSentiment(text) {
    const comprehend = new AWS.Comprehend({
        apiVersion: "2017-11-27",
        region: config.aws.comprehend.region,
        credentials: {
            accessKeyId: authState.credentials.accessKeyId,
            secretAccessKey: authState.credentials.secretAccessKey,
            sessionToken: authState.credentials.sessionToken
        }
    });
    return comprehend.detectTargetedSentiment({
        LanguageCode: 'en',
        Text: text
    }).promise();
}