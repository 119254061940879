import config from '../../config.json';
import state from './auth';
import { makePersistentTransport } from './transport.js';

let transport = null;
let unsubscribe = null;

let siteId = null;
let siteData = null;

state.subscribe((value) => {
    siteId = value.site;
    siteData = config.sites.find((site) => site.id === siteId);
    if(siteData) {
        subscriptions.forEach(sub => sub(siteData));
        if(!transport) {
            transport = makePersistentTransport('site:theme', true);
            unsubscribe = transport.subscribe((value) => {
                siteData.theme = value;
                subscriptions.forEach(sub => sub(siteData));
            });
        }
    }
});

const subscriptions = new Set();

// store
export const data = {
    subscribe: (subscription) => {
        subscriptions.add(subscription);
        if(siteData) subscription(siteData);
        return () => {
            subscriptions.delete(subscription);
            if(!subscriptions.size) {
                if(unsubscribe) {
                    unsubscribe();
                    unsubscribe = null;
                }
            }
        }
    }
};

/**
 * @param {string} theme 
 */
export function selectTheme(theme) {
    if(!transport) return;
    transport.set(theme);
};