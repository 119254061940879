const subscribers = [];

const bc = new BroadcastChannel('dbs-services');
bc.addEventListener('message', event => {
    const msg = event.data;
    const idx = msg.indexOf(':');
    if(idx < 0) return;
    let format = msg.substr(0, idx);
    let data = msg.substr(idx + 1);
    if(format === 'json') {
        data = JSON.parse(data);
    }
    subscribers.forEach(sub => sub(data));
});

export function subscribe(cb) {
    subscribers.push(cb);
    return {
        unbind: () => {
            const idx = subscribers.indexOf(cb);
            if(idx < 0) return false;
            subscribers.splice(idx, 1);
            return false;
        }
    }
}

export function publish(message) {
    if(typeof message === 'string') {
        bc.postMessage('string:' + message);
    } else {
        bc.postMessage('json:' + JSON.stringify(message));
    }
}