<script>
    import Modal from '../components/Modal.svelte';
    import Button from '../components/widget/Button.svelte';
    import ButtonStrip from '../components/widget/ButtonStrip.svelte';
    import Keypad from '../components/widget/Keypad.svelte';
    import Input from '../components/widget/Input.svelte';
    import Spacer from '../components/widget/Spacer.svelte';
    import config from '../../config.json';
    import OverlaySpinner from '../components/widget/OverlaySpinner.svelte';
    import { signIn } from '../store/auth';
    import { onMount, onDestroy, getContext } from 'svelte';
    import parseQuery from '../lib/parseQuery';

    const ModalManager = getContext('modal').context();
    onDestroy(() => ModalManager.close());

    let activeSite = config.sites[0].id;
    let showSiteSelector = true;
    let activeCode = '';
    let error = false;

    let loading = false;

    onMount(() => {
        const query = parseQuery(document.location.search);
        if(query && query.site) {
            const site = config.sites.find(site => site.id === query.site);
            if(site) {
                activeSite = query.site;
                showSiteSelector = false;
            }
        }
    });

    /**
     * select a site
     * @param {string} site
     */
    function selectSite(site) {
        activeSite = site;
    }

    /**
     * submit
    */
    async function submit() {
        try {
            loading = true;
            await signIn(`dbs-${activeSite}`, activeCode);
        } catch(_e) {
            loading = false;
            error = true;
        }
        /* if there was no error, component will be 'closed'/detached from the outside */
    }
</script>


<Modal>
    {#if loading}
        <OverlaySpinner></OverlaySpinner>
    {/if}
    {#if showSiteSelector}
        <ButtonStrip>
            {#each config.sites as site}
                    <Button
                        on:click={()=>selectSite(site.id)}
                        active={activeSite==site.id}
                        >
                        {site.name}
                    </Button>
            {/each}
        </ButtonStrip>
        <Spacer></Spacer>
    {/if}
    <Input mode="code" bind:value={activeCode} bind:error />
    <Keypad bind:error on:input={(digit)=>{activeCode+=digit.detail;error=false}} />
    <Spacer></Spacer>
    <Button on:click={submit}>Login</Button>
</Modal>