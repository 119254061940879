<script>
    import { fade } from "svelte/transition";
</script>

<style>
    .wrapper {
        position: absolute;
        z-index: 1000;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 10%;
        padding-right: 10%;
        background-color: rgba(0, 0, 0, 0.5);
    }
    .modal {
        box-shadow: 2px 2px 4px black;
        position: relative;
        overflow: hidden;
        color: white;
        font-size: 24pt;
        padding: 12px;
        border-radius: 8px;
        background: #292a36;
        background: -moz-linear-gradient(top,  #292a36 0%, #1b1c25 100%);
        background: -webkit-linear-gradient(top,  #292a36 0%,#1b1c25 100%);
        background: linear-gradient(to bottom,  #292a36 0%,#1b1c25 100%);
        height: 80px;
        border: 2px solid rgba(150,150,150, 0.5);

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        width: calc(100vw - 50px);
        max-width: 480px;

        height: fit-content;
    }
</style>

<div transition:fade class="wrapper">
    <div class="modal">
        <slot></slot>
    </div>
</div>