import config from '../../../../config.json';
import state from '../../../store/auth';
import { readable } from 'svelte/store';
import { refresh } from '../../../store/auth';
import v4 from 'aws-signature-v4';
import mic from 'microphone-stream';
import { EventStreamCodec } from "@aws-sdk/eventstream-codec"; // for converting binary event stream messages to and from JSON
import { fromUtf8, toUtf8 } from "@aws-sdk/util-utf8-node"; // utilities for encoding and decoding UTF8
import { downsampleBuffer, pcmEncode } from './audioUtils.js';

let authState = null
state.subscribe(_state => authState = _state);

// our converter between binary event streams messages and JSON
const eventStreamMarshaller = new EventStreamCodec(toUtf8, fromUtf8);

let micStream = null;
let socket = null;
let inputSampleRate = 0;
let sampleRate = 44100;
let provideResult = null;

function createPresignedUrl() {
    if(!authState || !authState.credentials) {
        console.error('Transcribe: Not authenticated, aborting');
        return url;
    }
    if(+authState.credentials.expiration - Date.now() <= 5 * 60) {
        refresh()
            .catch(() => { /* nothing to do here */ });
        console.error('Transcribe: credentials (almost) expired, connection will likely fail');
    }

    let endpoint = "transcribestreaming." + config.aws.transcribe.region + ".amazonaws.com:8443";

    // get a preauthenticated URL that we can use to establish our WebSocket
    const result = v4.createPresignedURL(
        'GET',
        endpoint,
        '/stream-transcription-websocket',
        'transcribe',
        '',
        {
            signSessionToken: true,
            key: authState.credentials.accessKeyId,
            secret: authState.credentials.secretAccessKey,
            sessionToken: authState.credentials.sessionToken,
            protocol: 'wss',
            expires: 15,
            region: config.aws.transcribe.region,
            query: "language-code=" + 'en-US' + "&media-encoding=pcm&sample-rate=" + sampleRate
        }
      );
    //  return result;
    return result+'&X-Amz-Security-Token='+encodeURIComponent(authState.credentials.sessionToken);
/*
    return v4.createPresignedURL(
        'GET',
        endpoint,
        '/stream-transcription-websocket',
        'transcribe',
        createHash('sha256').update('', 'utf8').digest('hex'), {
            key: authState.credentials.accessKeyId,
            secret: authState.credentials.secretAccessKey,
            sessionToken: authState.credentials.sessionToken,
            protocol: 'wss',
            expires: 15,
            region: config.aws.transcribe.region,
            query: "language-code=" + 'en-US' + "&media-encoding=pcm&sample-rate=" + sampleRate
        }
    );
    */
}

function convertAudioToBinaryMessage(audioChunk) {
    let raw = mic.toRaw(audioChunk);

    if (raw == null)
        return;

    // downsample and convert the raw audio bytes to PCM
    let downsampledBuffer = downsampleBuffer(raw, inputSampleRate, sampleRate);
    let pcmEncodedBuffer = pcmEncode(downsampledBuffer);

    // add the right JSON headers and structure to the message
    let audioEventMessage = getAudioEventMessage(new Uint8Array(pcmEncodedBuffer));

    //convert the JSON object + headers into a binary event stream message
    let binary = eventStreamMarshaller.encode(audioEventMessage);

    return binary;
}

let streamAudioToWebSocket = function (userMediaStream) {
    //let's get the mic input from the browser, via the microphone-stream module
    micStream = new mic();

    micStream.on("format", function(data) {
        inputSampleRate = data.sampleRate;
    });

    micStream.setStream(userMediaStream);

    // Pre-signed URLs are a way to authenticate a request (or WebSocket connection, in this case)
    // via Query Parameters. Learn more: https://docs.aws.amazon.com/AmazonS3/latest/API/sigv4-query-string-auth.html
    let url = createPresignedUrl();

    //open up our WebSocket connection
    socket = new WebSocket(url);
    socket.binaryType = "arraybuffer";

    // when we get audio data from the mic, send it to the WebSocket if possible
    socket.onopen = function() {
        micStream.on('data', function(rawAudioChunk) {
            // the audio stream is raw audio bytes. Transcribe expects PCM with additional metadata, encoded as binary
            let binary = convertAudioToBinaryMessage(rawAudioChunk);

            if (socket.readyState === socket.OPEN)
                socket.send(binary);
        }
    )};

    // handle messages, errors, and close events
    wireSocketEvents();
}

function wireSocketEvents() {
    // handle inbound messages from Amazon Transcribe
    socket.onmessage = function (message) {
        //convert the binary event stream message to JSON
        let messageWrapper = eventStreamMarshaller.decode(new DataView(message.data));
        let messageBody = JSON.parse(String.fromCharCode.apply(String, messageWrapper.body));
        if (messageWrapper.headers[":message-type"].value === "event") {
            handleEventStreamMessage(messageBody);
        }
        else {
            console.error(messageBody.message);
            stop();
            // @TODO
            // showError(messageBody.Message);
            // toggleStartStop();
        }
    };

    socket.onerror = function () {
        console.error('WebSocket connection error. Try again.');
        stop();
    };
    
    socket.onclose = function (closeEvent) {
        micStream.stop();
        
        console.error('WebSocket connection closed. Try again.');
        stop();
    };
}

let handleEventStreamMessage = function (messageJson) {
    let results = messageJson.Transcript.Results;

    if (results.length > 0 && results[0].Alternatives.length > 0) {
        let transcript = results[0].Alternatives[0].Transcript;

        // fix encoding for accented characters
        transcript = decodeURIComponent(escape(transcript));

        updateResult(transcript, results[0].IsPartial);
    } else {
        updateResult();
    }
}

function getAudioEventMessage(buffer) {
    // wrap the audio data in a JSON envelope
    return {
        headers: {
            ':message-type': {
                type: 'string',
                value: 'event'
            },
            ':event-type': {
                type: 'string',
                value: 'AudioEvent'
            }
        },
        body: buffer
    };
}

function closeSocket() {
    if (socket.readyState === socket.OPEN) {
        micStream.stop();

        // Send an empty frame so that Transcribe initiates a closure of the WebSocket after submitting all transcripts
        let emptyMessage = getAudioEventMessage(new ArrayBuffer());
        let emptyBuffer = eventStreamMarshaller.encode(emptyMessage);
        socket.send(emptyBuffer);
    }
}

let promise = null;
let lastTranscription = 0;

let result = null;

function updateResult(text, partial) {
    if(text) {
        if(!partial) {
            result.final += text + '\n';
            result.partial = '';
        } else {
            result.partial = text;
        }
        lastTranscription = Date.now();   
    }
    result.silence = (Date.now() - lastTranscription);

    if(provideResult) {
        provideResult(result);
    }
}

export function start() {
    if(promise) return;

    lastTranscription = Date.now();
    result = {
        active: true,
        final: '',
        partial: '',
        silence: 0
    };

    // first we get the microphone input from the browser (as a promise)...
    promise = window.navigator.mediaDevices.getUserMedia({
        video: false,
        audio: true
    });

    // ...then we convert the mic stream to binary event stream messages when the promise resolves 
    promise
        .then(streamAudioToWebSocket) 
        .catch(function (error) {
            console.error(error);
        });
    
    return readable(result, (set) => {
        provideResult = set;
        updateResult();
    });
}

export function stop() {
    if(!promise) return;
    promise = null;
    result.active = false;
    closeSocket();
    socket = null;
    updateResult();
}

