<script>
  import { getContext } from 'svelte';
  export let isActive = false;
  export let isFirst = false;
  export let isHover = false;
  export let item = undefined;
  export let filterText = '';

  let itemClasses = '';
  $: {
    const classes = [];
    if (isActive) { classes.push('active'); }
    if (isFirst) { classes.push('first'); }
    if (isHover) { classes.push('hover'); }
    if (item.isGroupHeader) { classes.push('groupHeader'); }
    if (item.isGroupItem) { classes.push('groupItem'); }
    itemClasses = classes.join(' ');
  }

  const filter = getContext('filter');
  const showTags = filter && filter.tags || false;

</script>

<style>
  .item {
    display: flex;
    align-items: center;
    cursor: default;
    height: var(--height, 42px);
    line-height: var(--height, 42px);
    padding: var(--itemPadding, 0 20px);
    color: var(--itemColor, inherit);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .groupHeader {
    text-transform: var(--groupTitleTextTransform, uppercase);
  }
  .groupItem {
    padding-left: var(--groupItemPaddingLeft, 40px);
  }
  .item:active {
    background: var(--itemActiveBackground, #b9daff);
  }
  .item.active {
    background: var(--itemIsActiveBG, #007aff);
    color: var(--itemIsActiveColor, #fff);
  }
  .item.first {
    border-radius: var(--itemFirstBorderRadius, 4px 4px 0 0);
  }
  .item.hover:not(.active) {
    background: var(--itemHoverBG, #e7f2ff);
  }
  .badge span {
      border-radius: 4px;
      background-color: rgb(204, 204, 95);
      color: black;
      font-size: 10pt;
      margin: 4px;
      padding: 4px;
      height: 1em;
  }
  .badge.favorite span {
    background-color: orange;
  }
  .badge.location span {
    background-color: rgb(95, 204, 95);
  }
  .badge.type span {
    background-color: rgb(95, 95, 204);
  }
  .tag {
    flex-grow: 1;
    margin-left: 10px;
  }
</style>

<div class="item {filterText && filterText.trim() || item.value === 'favorite' || item.tag || item.location || item.type ? itemClasses : 'text-muted'}">
    {#if Object.prototype.hasOwnProperty.call(item, 'text')}
        <ion-icon name="search-outline"></ion-icon>
        <span class="tag">
          {#if !filterText || !filterText.trim()}
            {#if showTags}
              Start typing to search or to filter by location, tag or asset type
            {:else}
              Start typing to search
            {/if}
          {:else}
            Search: {@html filterText}
          {/if}
        </span>
    {:else if item.value === 'favorite'}
      <ion-icon name="star"></ion-icon>
      <span class="tag">Favorite</span>
      <div class="badge favorite"><span>{item.count}</span></div>
    {:else if item.location}
        <ion-icon name="location-outline"></ion-icon>
        <span class="tag">{@html item.location}</span>
        <div class="badge location"><span>{item.count}</span></div>
    {:else if item.type}
        <ion-icon name="cube-outline"></ion-icon>
        <span class="tag">{@html item.type}</span>
        <div class="badge type"><span>{item.count}</span></div>
    {:else}
        <ion-icon name="pricetag-outline"></ion-icon>
        <span class="tag">{@html item.tag}</span>
        <div class="badge"><span>{item.count}</span></div>
    {/if}
</div>