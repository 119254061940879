<script>
    import CameraFeed from './components/CameraFeed.svelte';
    import { makePersistentTransport } from '../../store/transport.js';
    import { onMount } from 'svelte';
    import { data as siteData } from "../../store/site";
    import pods from "../../store/pods";
    
    let podState = 'idle';
    let podList = [];
    let url = null;
    let iframeSize = 'default';

    function updatePod() {
        const pod = podList.find(pod => podState === 'demo-api-' + pod.id);
        url = pod && pod.url && pod.url.primary || null;
        iframeSize = pod && pod.size || 'default';
    }

    let data = makePersistentTransport('pod:cv:ctrl', true);
    onMount(() => {
        const unbindState = data.subscribe((_state) => {
            podState = _state;
            updatePod();
        });
        const unbindPods = pods('cv').subscribe((_pods) => {
            podList = _pods;
            updatePod();
        });
        return () => {
            unbindState();
            unbindPods();
        }
    });
</script>

<style>
    .video {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .wrapper {
        width: 100%;
        height: 100%;
        background-size: cover;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .wrapper.cmb {
        background-image: url('/assets/background_primary_cmb.png');
    }
    .wrapper.aws {
        background-image: url('/assets/background_primary_aws.png');
    }
    .wrapper.bcg {
        background-image: url('/assets/background_primary_bcg.png');
    }
    .padding {
        padding: 100px;
        width: 100%;
    }
    iframe {
        width: 100%;
        height: 100%;
        border:none;
    }
    iframe.default, iframe.no-header {
        left: 60px;
        width: 1720px;
        top: 60px;
        height: 960px;
    }
    iframe.fullscreen {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }
  </style>
  
{#if podState === 'idle'}
    <video src="/assets/pods/cv/{$siteData.theme}_idle_video.mp4" autoPlay loop muted class="video" crossorigin="anonymous" />
{:else}
    <div class="wrapper {$siteData.theme}">
        {#if podState === 'demo-pose'}
            <div class="padding">
                <CameraFeed></CameraFeed>
            </div>
        {:else if url}
            <iframe scrolling="no" class={iframeSize} src={url} title="Demo Content" sandbox="allow-forms allow-same-origin allow-orientation-lock allow-pointer-lock allow-presentation allow-scripts" allow="autoplay *; geolocation *; gyroscope *; accelerometer *; microphone *; camera *; screen-wake-lock *"></iframe>
        {/if}
    </div>
{/if}