<script>
    import { makePersistentTransport } from "../../store/transport.js";
    import { onMount } from "svelte";
    import { data as siteData } from "../../store/site";
    import pods from "../../store/pods";

    const presets = [
        {
            id: "trueColor",
            name: "True color",
            description: "Based on bands 4,3,2",
            bands: ["B04", "B03", "B02"],
        },
        {
            id: "falseColor",
            name: "False color",
            description: "Based on bands 8,4,3",
            bands: ["B08", "B04", "B03"],
        },
        {
            id: "falseColorUrban",
            name: "False color (urban)",
            description: "Based on bands 12,11,4",
            bands: ["B12", "B11", "B04"],
        },
        {
            id: "ndvi",
            name: "NDVI",
            description: "Based on combination of bands (B8-B4)/(B8+B4)",
            bands: ["B05", "B06", "B07"],
        },
        {
            id: "moisture",
            name: "Moisture index",
            description:
                "Based on combination of bands (B8A - B11)/(B8A + B11)",
            bands: ["B8A", "B11"],
        },
        {
            id: "swir",
            name: "SWIR",
            description: "Based on bands 12,8A,4",
            bands: ["B12", "B8A", "B04"],
        },
        {
            id: "ndwi",
            name: "NDWI",
            description: "Based on combination of bands (B3 - B8)/(B3 + B8)",
            bands: ["B03", "B08"],
        },
        {
            id: "ndsi",
            name: "NDSI",
            description: "Based on bands 2,11,12",
            bands: ["B02", "B11", "B12"],
        },
    ];

    const bandObjects = [
        { id: "B01", name: "Band 1", path: "M360,-1 L360,1100" },
        { id: "B02", name: "Band 2", path: "M390,-1 L390,1100" },
        { id: "B03", name: "Band 3", path: "M420,-1 L420,1100" },
        { id: "B04", name: "Band 4", path: "M450,-1 L450,1100" },
        { id: "B05", name: "Band 5", path: "M480,-1 L480,1100" },
        { id: "B06", name: "Band 6", path: "M510,-1 L510,1100" },
        { id: "B07", name: "Band 7", path: "M540,-1 L540,1100" },
        { id: "B08", name: "Band 8", path: "M570,-1 L570,1100" },
        { id: "B8A", name: "Band 8A", path: "M600,-1 L600,1100" },
        { id: "B09", name: "Band 9", path: "M630,-1 L630,1100" },
        { id: "B10", name: "Band 10", path: "M660,-1 L660,1100" },
        { id: "B11", name: "Band 11", path: "M690,-1 L690,1100" },
        { id: "B12", name: "Band 12", path: "M720,-1 L720,1100" },
    ];

    let presetId = presets[0].id;
    let preset = presets[0];

    let podState = 'idle';
    let podList = [];
    let url = null;
    let iframeSize = 'default';
    let description = '';

    function updatePod() {
        const pod = podList.find(pod => podState === 'demo-api-' + pod.id);
        url = pod && pod.url && pod.url.secondary || null;
        iframeSize = pod && pod.size || 'default';
        description = pod && pod.description || podState;
    }

    let data = makePersistentTransport('pod:bigdata:ctrl', true);
    let demoData = makePersistentTransport("pod:bigdata:demo-satellite:band", true);
    onMount(() => {
        const unbindData = data.subscribe((_state) => {
            podState = _state;
            updatePod();
        });
        const unbindDemo = demoData.subscribe((_state) => {
            presetId = _state;
            preset = presets.find((p) => p.id === presetId);
            if (!preset) {
                presetId = presets[0].id;
                preset = presets[0];
            }
        });
        const unbindPods = pods('bigdata').subscribe((_pods) => {
            podList = _pods;
            updatePod();
        });
        return () => {
            unbindData();
            unbindDemo();
            unbindPods();
        };
    });
</script>

<main class="{$siteData.theme}">
    {#if podState === 'idle'}
        <video src="/assets/pods/bigdata/{$siteData.theme}_idle_poster.mp4" autoPlay loop muted class="video" crossorigin="anonymous" />
    {:else if podState === 'demo-satellite'}
        <div class="satellite" />
        <section>
            <img src="/assets/{$siteData.theme}_logo.svg">
            <h1 class="title">Big Data</h1>
            <hr />
            <h2 class="title">Sentinel 2</h2>
            <div class="wavebandButtons">
                {#each bandObjects as band}
                    <div
                        disabled={preset.bands.indexOf(band.id) >= 0
                            ? null
                            : "disabled"}
                        class="wavebandBtn"
                    >
                        {band.name}
                    </div>
                {/each}
            </div>
            <svg
                viewBox="0 0 730 1100"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                class="bandLines"
            >
                <g stroke="none" fill="none" transform="scale(1)">
                    <g transform="translate(0,0)">
                        <g transform="translate(0,0)">
                            {#each bandObjects as band}
                                <path
                                    d={band.path}
                                    id={band.id}
                                    stroke="#2d3f95"
                                    stroke-linecap="square"
                                    stroke-width={preset.bands.indexOf(band.id) >= 0
                                        ? 4
                                        : 1}
                                />
                            {/each}
                        </g>
                    </g>
                </g>
            </svg>
        </section>
        <div class="satellite_foreground" />
        <div class="satGrid" />
    {:else if url}
        {#if iframeSize == 'default'}
            <section>
                <img src="/assets/{$siteData.theme}_logo.svg">
                <h1 class="title">Big Data</h1>
                <hr />
                <h2 class="title">{description}</h2>
            </section>
        {/if}
        <iframe scrolling="no" class={iframeSize} src={url} title="Demo Content" sandbox="allow-forms allow-same-origin allow-orientation-lock allow-pointer-lock allow-presentation allow-scripts" allow="autoplay *; geolocation *; gyroscope *; accelerometer *; microphone *; camera *; screen-wake-lock *"></iframe>
    {/if}
</main>

<style>
    main {
        font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
        font-size: 12px;
        background-repeat: no-repeat;
        background-size: contain;
        width: 100%;
        height: 100%;
        overflow: hidden;
    }
    main.cmb {
        background-image: url('/assets/background_secondary_cmb.png');
    }
    main.aws {
        background-image: url('/assets/background_secondary_aws.png');
    }
    main.bcg {
        background-image: url('/assets/background_secondary_bcg.png');
    }

    .wavebandButtons {
        position: absolute;
        width: 950px;
        height: 100px;
        top: 292px;
        left: 4px;
        max-width: 940px;
        line-height: 30px;
    }

    .wavebandBtn {
        cursor: pointer;
        font-family: "Suisse Int'l";
        font-weight: bold;
        float: left;
        min-width: 115px;
        font-size: 20px;
    }

    .wavebandBtn[disabled] {
        font-weight: normal;
    }

    section {
        position: absolute;
        left: 60px;
        top: 40px;
        width: 950px;
    }

    section img {
        position: absolute;
        left: 4px;
        top: 27px;
    }

    section h1 {
        position: absolute;
        font-family: "Suisse Int'l";
        font-weight: bold;
        font-size: 62px;
        top: 135px;
        left: 4px;
        margin-top: 0px;
        margin-bottom: 0px;
    }

    section hr {
        position: absolute;
        background-color: rgb(0, 0, 0);
        color: rgb(0, 0, 0);
        border: rgb(0, 0, 0);
        height: 4px;
        width: 960px;
        top: 229px;
        margin-top: 0px;
        margin-bottom: 0px;
    }

    section h2 {
        position: absolute;
        font-family: "Suisse Int'l";
        font-weight: bold;
        font-size: 31px;
        top: 247px;
        left: 4px;
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .satellite {
        position: absolute;
        left: 60px;
        top: 420px;
        width: 960px;
        height: 1440px;
        background-image: url("/assets/pods/bigdata/satellite.png");
    }

    .satellite_foreground {
        position: absolute;
        left: 60px;
        top: 420px;
        width: 960px;
        height: 1440px;
        background-image: url("/assets/pods/bigdata/satellite_foreground.png");
    }

    .satGrid {
        position: absolute;
        left: 295px;
        top: 655px;
        width: 730px;
        height: 370px;
        background-image: url("/assets/pods/bigdata/satellitemarkers.png");
        background-repeat: no-repeat;
    }

    .bandLines {
        position: absolute;
        left: -60px;
        top: 820px;
        width: 730px;
        height: 1100px;
    }
    iframe {
        border:none;
        position: absolute;
    }
    iframe.default {
        left: 60px;
        width: 960px;
        top: 360px;
        height: 1500px;
    }
    iframe.no-header {
        left: 60px;
        width: 960px;
        top: 60px;
        height: 1800px;
    }
    iframe.fullscreen {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }
</style>
