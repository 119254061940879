
import { readable } from 'svelte/store';

/**
 
{
    "id": number,
    "url": {
        "primary": string,
        "secondary": string,
        "control": string|undefined
    },
    "thumbnail": string|undefined,
    "size": "default" | "fullscreen" | "no-header" | undefined,
    "category": "nlp" | "cv" | "bigdata",
    "description": string,
    "name": string
}

 */

/**
 * 
 */
export default readable(null, (set) => {
    fetch('/assets/catalog.json')
        .then((result) => {
            return result.json();
        }).then(data => {
            set(data);
        }).catch(_e => {
            set(null);
        });
    return () => {
        unsubscribe();
    };
});