<script>
    import Filter from './components/widget/Filter.svelte';
    import OverlaySpinner from "./components/widget/OverlaySpinner.svelte";
    import catalog from "./store/catalog";
    import { signIn, signOut, signInInteractive, user } from './lib/authBCG';

    let loading = true;
    let userData;

    let filter = "";

    let content = {
        items: []
    };
    let tags = [];
    let favorites;
    let favoriteTag = {
        value: 'favorite',
        count: 0
    };
    
    function updateFilter() {
        if(!$catalog) return false;
        content = {
            items: $catalog.items.filter(item => {
                let res = true;
                if(filter && filter.favorite) {
                    res = res && favorites.ids[item.id];
                }
                if(filter && filter.locations) {
                    filter.locations.$contains.forEach(neededLocation => {
                        res = res && item.locations && item.locations.find(location => location === neededLocation);
                    });
                }
                if(filter && filter.tags) {
                    filter.tags.$contains.forEach(neededTag => {
                        res = res && (item.tags && item.tags.find(tag => tag === neededTag));
                    });
                }
                if(filter && filter.types) {
                    filter.types.$contains.forEach(neededType => {
                        res = res && item.type === neededType;
                    });
                }
                if(filter && filter.text) {
                    filter.text.$contains.forEach(word => {
                        const match2 = '(^|\\s)'+word.toLowerCase();
                        res =  res && (item.name.toLowerCase().match(match2) != null ||
                            item.summary.toLowerCase().match(match2) != null
                        );
                    });
                }
                return res;
            })
        };
        tags = [];
        const tagMap = {}, locationMap = {}, typeMap = {};
        for(let i = 0, n = $catalog.items.length; i < n; i++) {
            const item = $catalog.items[i];
            item.tags.forEach(tag => {
                if(!tagMap[tag]) tagMap[tag] = 1;
                else tagMap[tag]++;
            });
            item.locations.forEach(location => {
                if(!locationMap[location]) locationMap[location] = 1;
                else locationMap[location]++;
            });
            if(!typeMap[item.type]) typeMap[item.type] = 1;
            else typeMap[item.type]++;
        }
        tags.push(favoriteTag);
        for(let key in typeMap) {
            tags.push({
                value: 'type:'+key,
                count: typeMap[key],
                type: key
            });
        }
        for(let key in tagMap) {
            tags.push({
                value: 'tag:'+key,
                count: tagMap[key],
                tag: key
            });
        }
        for(let key in locationMap) {
            tags.push({
                value: 'location:'+key,
                count: locationMap[key],
                location: key
            });
        }
        return true;
    }
    function loadFavorites() {
        const value = localStorage.getItem('favorites');
        try {
            favorites = JSON.parse(value);
        } catch(_){ 
            /* nothing to do here */
        }
        if(!favorites) {
            favorites = {count:0, ids:{}};
        }
        favoriteTag.count = favorites.count;
    }
    function toggleFavorite(item) {
        if(favorites.ids[item.id]) {
            delete favorites.ids[item.id];
            favorites.count--;
        } else {
            favorites.ids[item.id] = true;
            favorites.count++;
        }
        localStorage.setItem('favorites', JSON.stringify(favorites));
        favoriteTag.count = favorites.count;
        favorites = favorites;
        
        if(filter && filter.favorite) updateFilter();
    }
    function onStorageEvent(event) {
        if (event.storageArea === localStorage) {
            loadFavorites();
        }
    };
    $: filter, updateFilter();
    $: $catalog && updateFilter() && (loading = false);
    
    loadFavorites();
    
    signIn()
        .then(() => {
            userData = user();
            console.log('Logged in as ' + userData.name);
        })
		.catch(_error => {
            signInInteractive();
        })
</script>

<div class="wrapper">
    <div class="container">
        {#if loading || !userData}
            <OverlaySpinner>
                <div slot="caption">Loading</div>
            </OverlaySpinner>
        {/if}
        {#if userData}
            <div class="crumbs">
                <div
                    class="crumb"
                    on:click={() => {
                        open("");
                    }}
                >
                    BCG ICO Digital Asset Library
                </div>
                <div class="separator">
                    <ion-icon name="chevron-forward-outline" />
                </div>
            </div>
            <div class="menu">
                <label class="filter">
                    <ion-icon name="search-outline" />
                    <Filter bind:filter={filter} items={tags} />
                </label>
                <label class="user disabled">
                    <ion-icon size="large" style="color:lightgray" name="person-outline"></ion-icon> {userData.name} 
                </label>
                <label class="logout">
                    <ion-icon size="large" on:click={signOut} name="log-out-outline"></ion-icon>
                </label>
            </div>
            <div class="grid">
                {#each content.items as item}
                    <div class="object item">
                        <div class="actions">
                            <div class="action" on:click={() => open($catalog.link.replace('<FILE>', item.file))}>
                                <ion-icon name="open-outline"></ion-icon>
                            </div>
                            <div class="action" on:click={() => toggleFavorite(item)}>
                                {#if favorites.ids[item.id]}
                                    <ion-icon name="star-outline"></ion-icon>
                                {:else}
                                    <ion-icon name="star"></ion-icon>
                                {/if}
                            </div>
                        </div>
                        <img src={item.thumbnail} alt="Preview of first slide" />
                        <div class="name">{item.name}</div>
                        <div class="summary">{item.summary.length > 120 ? item.summary.substr(0, 120) + '...' : item.summary}</div>
                        {#if item.tags || item.locations || favorites.ids[item.id]}
                            <div class="tags">
                                {#if item.tags}
                                    {#each item.tags as tag}
                                        <div class="tag"><ion-icon name="pricetag-outline"></ion-icon>&nbsp;{tag}</div>
                                    {/each}
                                {/if}
                                {#if item.locations}
                                    {#each item.locations as location}
                                        <div class="tag location"><ion-icon name="location-outline"></ion-icon>&nbsp;{location}</div>
                                    {/each}
                                {/if}
                                {#if item.type}
                                    <div class="tag type"><ion-icon name="cube-outline"></ion-icon>&nbsp;{item.type}</div>
                                {/if}
                                {#if favorites.ids[item.id]}
                                    <div on:click={() => toggleFavorite(item)} class="tag favorite">&nbsp;<ion-icon name="star"></ion-icon>&nbsp;</div>
                                {/if}
                            </div>
                        {/if}
                    </div>
                {/each}
            </div>
        {/if}
    </div>
</div>

<svelte:window on:storage={onStorageEvent} />

<style>
    .search {
        width: 320px;
        vertical-align: middle;
        border: none;
        border-radius: 4px;
        background-color: rgba(255,255,255,0.1);
        margin-left: 4px;
        height: 1.5em;
        color: white;
    }

    .wrapper {
        position: absolute;
        z-index: 1000;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 10%;
        padding-right: 10%;
        background-color: rgba(0, 0, 0, 0.5);
    }
    .container {
        box-shadow: 2px 2px 4px black;

        position: relative;
        overflow: hidden;
        color: white;
        font-size: 24pt;
        padding: 8px;
        padding-top: 0px;
        border-radius: 8px;
        background: #292a36;
        background: -moz-linear-gradient(top, #292a36 0%, #1b1c25 100%);
        background: -webkit-linear-gradient(top, #292a36 0%, #1b1c25 100%);
        background: linear-gradient(to bottom, #292a36 0%, #1b1c25 100%);
        height: 80px;
        border: 2px solid rgba(150, 150, 150, 0.5);

        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: start;

        width: calc(100vw - 50px);
        height: calc(100vh - 50px);

        padding: 20px;
    }
    .grid {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: start;
        align-items: start;
        overflow-y: auto;
    }
    .object {
        width: 256px;
        margin: 10px;
        border-radius: 4px;
        background-color: rgba(255, 255, 255, 0.1);
        display: flex;
        flex-direction: column;
        font-size: 12pt;
        align-items: center;
        justify-content: start;
        overflow: hidden;
        box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2),
            0 4px 20px 0 rgba(0, 0, 0, 0.1);
    }
    .object img {
        width: 100%;
    }
    .object .name {
        padding: 8px;
        background-color: rgba(255, 255, 255, 0.2);
        width: 100%;
        min-height: 3em;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .object .summary {
        width: 100%;
        padding: 8px;
        height: 100px;
    }
    .object .tags {
        width: 100%;
        padding: 8px;
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: start;
        flex-wrap: wrap;
    }
    .object .tags .tag {
        display: flex;
        align-items: center;
        border-radius: 4px;
        background-color: rgb(204, 204, 95);
        color: black;
        font-size: 10pt;
        margin: 4px;
        padding: 4px;
    }
    .object .tags .tag.location {
        background-color: rgb(95, 204, 95);
    }
    .object .tags .tag.type {
        background-color: rgb(95, 95, 204);
    }
    .object .tags .tag.favorite {
        background-color: orange;
    }
    .crumbs {
        display: flex;
        justify-content: start;
        align-items: center;
        flex-direction: row;
        width: 100%;
        padding: 10px;
        border-radius: 4px;
        background-color: rgba(255, 255, 255, 0.1);
        margin-bottom: 10px;
        font-size: 12pt;
        box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2),
            0 4px 20px 0 rgba(0, 0, 0, 0.1);
    }
    .crumb {
        cursor: pointer;
        color: white;
    }
    .crumb .right {
        display: flex;
        align-items: center;
    }
    .separator {
        display: flex;
        opacity: 0.2;
        color: white;
    }
    .menu {
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: center;
        width: 100%;
        margin-bottom: 10px;
        font-size: 12pt;
    }
    .menu .filter {
        flex-grow: 1;
    }
    .menu .user, .menu .logout {
        flex-grow: 0;
        flex-shrink: 1;
        height: 100%;
    }
    .menu .disabled {
        pointer-events: none;
    }
    .menu div, .menu label {
        margin-right: 10px;
        display: flex;
        cursor: pointer;
        padding: 8px;
        border-radius: 4px;
        color: #bbb;
        border: 2px solid #333;
        justify-content: center;
        align-items: center;
        flex-grow: 1;
    }
    .menu div:hover, .menu label:hover {
        background-color: rgba(255, 255, 255, 0.2);
        border: 2px solid #777;
    }
    .menu ion-icon {
        margin-right: 5px;
    }
    .object {
        position: relative;
    }
    .object .actions {
        position: absolute;
        right: 10px;
        top: 10px;
        display: none;
    }
    .object:hover .actions {
        display: block;
    }
    .action {
        background-color: rgba(255,255,255,0.5);
        border-radius: 4px;
        padding: 5px;
        cursor: pointer;
        border: 1px solid white;
        color: black;
        margin-bottom: 5px;
    }
    .action:hover {
        background-color: rgba(255,255,255,0.75);
        opacity: 1;
    }
</style>
