import { readable } from 'svelte/store';
import state from './auth';
import { client as mqtt } from '../lib/mqtt';

let site = null;

//-------

let setRawContent = (value) => { _rawContent = value; };
let _rawContent = null;
export const rawContent = readable(null, (set) => {
    setRawContent = (value) => {
        _rawContent = value;
        set(value);
    };
    if(_rawContent) set(_rawContent);
});

let setContent = (value) => { _content = value; };
let _content = null;
export const content = readable(null, (set) => {
    setContent = (value) => {
        _content = value;
        set(value);
    };
});

let setLink = (value) => { _link = value; };
let _link = false;
export const link = readable(_link, (set) => {
    setLink = (value) => {
        _link = value;
        set(value);
    };
    if(_link) {
        set(_link);
    }
});

let setScreen = (value) => { _screen = value; };
let _screen = null;
let _resetScreenTimeout = null;
export const screen = readable(_screen, (set) => {
    setScreen = (value) => {
        if(_resetScreenTimeout) {
            clearTimeout(_resetScreenTimeout);
            _resetScreenTimeout = null;
        }
        _screen = value;
        set(value);
    };
    if(_screen) {
        set(_screen);
    }
});

export function getData() {
    if(!_content || !_screen) return null;
    for(let i = 0, n = _content.length; i < n; i++) {
        for(let j = 0, m = _content[i].childs.length; j < m; j++) {
            if(_content[i].childs[j].presentation === _screen.presentation) {
                return _content[i].childs[j];
            }
        }
    }
    return null;
}
let setData = (value) => { _data = value; };
let _data = null;
screen.subscribe(() => setData(getData()));
content.subscribe(() => setData(getData()));
export const data = readable(_data, (set) => {
    setData = (value) => {
        _data = value;
        set(value);
    };
    if(_data) {
        set(_data);
    }
});

export async function clear() {
    if(!site) return;
    mqtt.publish(`dbs/${site}/screen/cmd`, {"action":"download","library":null});
}

export async function download(library) {
    if(!site) return;
    if(Array.isArray(library)) {
        mqtt.publish(`dbs/${site}/screen/cmd`, {"action":"download","library":library});
    } else if(typeof library === 'string'){
        mqtt.publish(`dbs/${site}/screen/cmd`, {"action":"download","library":library});
    }
}

export function open(presentation) {
    if(!site || (_screen.presentation === presentation && _screen.slide === 0)) return;
    mqtt.publish(`dbs/${site}/screen/cmd`, {"action":"open","presentation":presentation});
    
    const confirmedScreen = JSON.parse(JSON.stringify(_screen));
    
    _screen.presentation = presentation;
    _screen.slide = 0;
    _screen.slides = 1;
    _screen.version = 0;
    _screen.loading = true;
    setScreen(_screen);

    // if new screen is not confirmed quickly, revert to old state
    if(_resetScreenTimeout) {
        clearTimeout(_resetScreenTimeout);
    }
    _resetScreenTimeout = setTimeout(() => {
        setScreen(confirmedScreen);
    }, 10 * 1000);
}

export function next() {
    if(!site) return;
    mqtt.publish(`dbs/${site}/screen/cmd`, {"action":"next"});
}

export function prev() {
    if(!site) return;
    mqtt.publish(`dbs/${site}/screen/cmd`, {"action":"prev"});
}

//-------
const subs = [];

state.subscribe(state => {
    subs.forEach(sub => sub());
    subs.length = 0;
    if(state.user && state.site) {
        let topic;
        site = state.site;

        topic = `dbs/${site}/screen/connected`;
        subs.push(mqtt.subscribe(topic, data => setLink(data)));

        topic = `dbs/${site}/screen/state`;
        subs.push(mqtt.subscribe(topic, data => setScreen(data)));

        const chunks = {};
        let chunksId = 0;
        let numChunks = 0;

        function handleContent() {
            let body = '';
            for(let i = 0; i < numChunks; i++) {
                const chunk = chunks[i];
                if(!chunk || chunk.id != chunksId) return;
                body += chunk.body;
            }
            try {
                body = JSON.parse(body);
            } catch(_e) {
                return;
            }

            const groups = [];
            const contextModel = body && body.meta && body.meta.model || 'world';

            function visit(node, name = null) {
                if(!node) return;
                const group = {
                    name: (name ? name + ' - ' : '') + node.name,
                    childs: []
                };
                const idx = groups.length;
                if(node.presentation) {
                    const context = node.meta && node.meta.context && node.meta.context.find(context => context.model === contextModel) || null;
                    group.childs.push({
                        title: node.meta && node.meta.name || node.title,
                        name: node.name,
                        presentation: node.presentation,
                        thumbnail: node.meta && node.meta.thumbnail || node.thumbnail,
                        slides: node.slides,
                        model: node.meta && node.meta.model || null,
                        url: node.meta && node.meta.url || null,
                        context: context && {
                            position: context.position,
                            actions: context.actions
                        } || null
                    });
                }
                if(node.childs) {
                    for(let i = 0, n = node.childs.length; i < n; i++) {
                        const child = node.childs[i];
                        if(child.type === 'folder') {
                            visit(child, (name === null ? '' : (name ? name + ' - ' + node.name : node.name)));
                        } else {
                            const context = child.meta && child.meta.context && child.meta.context.find(context => context.model === contextModel) || null;
                            group.childs.push({
                                title: child.meta && child.meta.name || child.title,
                                name: child.name,
                                presentation: child.presentation,
                                thumbnail: child.meta && child.meta.thumbnail || child.thumbnail,
                                slides: child.slides,
                                model: child.meta && child.meta.model || null,
                                url: child.meta && child.meta.url || null,
                                context: context && {
                                    position: context.position,
                                    actions: context.actions
                                } || null
                            });
                        }
                    }
                }
                if(group.childs.length) {
                    groups.splice(idx, 0, group);
                }
            }
            visit(body);

            setContent(groups);
            setRawContent(body);

            // clear id
            chunksId = numChunks = 0;
        }

        let chunkReqTimeout = 0;

        topic = `dbs/${site}/content`;
        subs.push(mqtt.subscribe(topic, data => {
            chunksId = data.id;
            numChunks = data.chunks;
            handleContent();

            chunkReqTimeout = setTimeout(() => {
                mqtt.publish(`dbs/${site}/content/cmd`, {"action":"request"});
            }, 1000);
        }));

        topic = `dbs/${site}/content/stream`;
        subs.push(mqtt.subscribe(topic, data => {
            let chunk = data.chunk;
            chunks[chunk] = data;
            handleContent();

            if(chunkReqTimeout) {
                clearTimeout(chunkReqTimeout);
                chunkReqTimeout = 0;
            }
        }));
    }
});