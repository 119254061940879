<script>
    import { fade } from "svelte/transition";
</script>

<div transition:fade class="overlay">
</div>

<style>
    .overlay {
        align-items: center;
        justify-content: center;
        position: absolute;
        flex-direction: column;
        left: 0;
        top: 0;
        display: flex;
        width: 100%;
        height: 100%;
        flex-direction: column;
        background-color: rgba(0, 0, 0, 0.25);
        z-index: 100;
        border-radius: 0px 0px 4px 4px;
        color: white;
        backdrop-filter: blur(2px) grayscale();
        -webkit-backdrop-filter: blur(2px) grayscale();
        text-shadow: 0px 0px 2px #000000;
    }
    .caption {
        color: white;
        font-weight: bold;
        font-size: 40pt;
    }
    .overlay ion-icon {
        transform: scale(4);
        margin-bottom: 50px;
    }
</style>

<div class="overlay">
    <ion-icon size="large" name="cloud-upload-outline"></ion-icon>
    <div class="caption">
        Drop files here to upload
    </div>
</div>
