<script>
    import Modal from '../components/Modal.svelte';
    import Button from '../components/widget/Button.svelte';
    import Input from '../components/widget/Input.svelte';
    import Spacer from '../components/widget/Spacer.svelte';
    import OverlaySpinner from '../components/widget/OverlaySpinner.svelte';
    import { renameObject } from '../store/library';
    import { onDestroy, getContext, createEventDispatcher } from 'svelte';

    const ModalManager = getContext('modal').context();
    onDestroy(() => ModalManager.close());

    export let path;
    export let name;
    export let type;

    let dispatch = createEventDispatcher();

    let error = false;
    let loading = false;

    /**
     * submit
    */
    async function submit() {
        try {
            loading = true;
            await renameObject((path ? path + '/' : '') + name + (type === 'folder' ? '/' : ''), (path ? path + '/' : '') + name + (type === 'folder' ? '/' : ''));
            dispatch('success');
        } catch(_e) {
            loading = false;
            error = true;
        }
        /* if there was no error, component will be 'closed'/detached from the outside */
    }

    /**
     * close
     */
    function close() {
        dispatch("cancel");
    }
</script>

<style>
</style>

<Modal>
    {#if loading}
        <OverlaySpinner></OverlaySpinner>
    {/if}
    <Input bind:value={name} bind:error />
    <Spacer></Spacer>
    <Button on:click={submit}>Rename</Button>
    <Spacer></Spacer>
    <Button on:click={close}>Cancel</Button>
</Modal>