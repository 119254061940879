import PodCVPrimary from './cv/Primary.svelte';
import PodCVSecondary from './cv/Secondary.svelte';
import PodCVControl from './cv/Control.svelte';

import PodBigDataPrimary from './bigdata/Primary.svelte';
import PodBigDataSecondary from './bigdata/Secondary.svelte';
import PodBigDataControl from './bigdata/Control.svelte';

import PodNLPPrimary from './nlp/Primary.svelte';
import PodNLPSecondary from './nlp/Secondary.svelte';
import PodNLPControl from './nlp/Control.svelte';

export default [
    {
        components: {
            primary: PodCVPrimary,
            secondary: PodCVSecondary,
            control: PodCVControl
        },
        id: 'cv',
        title: 'Computer Vision'
    },
    {
        components: {
            primary: PodBigDataPrimary,
            secondary: PodBigDataSecondary,
            control: PodBigDataControl
        },
        id: 'bigdata',
        title: 'Big Data'
    },
    {
        components: {
            primary: PodNLPPrimary, 
            secondary:PodNLPSecondary,
            control: PodNLPControl
        },
        id: 'nlp',
        title: 'NLP'
    }
]