<script>
    import { screen, getData, content, link, prev, next, open} from '../../store/content';
    import { Auth } from 'aws-amplify';
    import OverlaySpinner from '../../components/widget/OverlaySpinner.svelte';
    import { getContext, onDestroy } from 'svelte';
    import config from '../../../config.json';
    import Download from '../../modals/LibraryDownload.svelte';
    import Settings from '../../modals/Settings.svelte';
    import { data as siteData } from "../../store/site";
    import { createEventDispatcher } from 'svelte';
    const dispatch = createEventDispatcher();

    export let mode = 'grid';

    const Modal = getContext('modal').context();
    onDestroy(() => Modal.close());

    let data = null;
    let timeout = null;
    function updateData() {
        data = getData();
        // close presentation after 30 minutes without activity
        if(timeout) clearTimeout(timeout);
        if(data) {
            timeout = setTimeout(() => {
                open(null);
            }, 30 * 60 * 1000);
        }
    }
    $: $screen && $content && updateData();
</script>

<style>
    .wrapper {
        width: 100%;
        position: absolute;
        bottom: -8px;
        left: 0;
        display: flex;
        justify-content: center;
        z-index: 1000;
    }
    .hud {
        position: relative;
        color: white;
        font-size: 24pt;
        width: 100%;
        background: #292a36;
        background: -moz-linear-gradient(top,  #292a36 0%, #1b1c25 100%);
        background: -webkit-linear-gradient(top,  #292a36 0%,#1b1c25 100%);
        background: linear-gradient(to bottom,  #292a36 0%,#1b1c25 100%);
        height: 160px;
        border-top: 2px solid rgba(150,150,150, 0.5);

        display: flex;
        align-items: center;
        justify-content: center;
    }
    .hud ion-icon {
        vertical-align: middle;
        font-size: 30pt;
    }
    .state {
        width: fit-content;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .frame {
        position: relative;
        width: 210px;
        height: 120px;
        border-radius: 4px;
        background-size: cover;
        background-color: black;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        text-align: center;
        font-size: 16pt;
    }
    .details {
        display: flex;
        flex-direction: column;
        margin-left: 40px;
    }
    .right {
        position: absolute;
        right: 50px;
        display: flex;
        flex-direction: row;
        justify-content: end;
        align-items: center;
    }
    .title {
        overflow: hidden;
        width: 400px;
        max-width: calc(100vw - 40px - 210px);
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .btn {
        padding: 5px;
        box-shadow: 0 4px 10px 0 rgba(0,0,0,.3),0 4px 20px 0 rgba(0,0,0,.1);
        margin: 5px;
        text-align: center;
        border-radius: 4px;
        background-color: rgba(255,255,255,0.1);
    }
    .link {
        padding: 5px;
        margin: 5px;
        text-align: center;
        opacity: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: white;
        font-size: 12pt;
    }
    .left {
        position: absolute;
        left: 10px;
    }
    .disabled {
        opacity: 0.25;
    }
</style>
<div class="wrapper">
    <div class="hud">
        <div class="left">
            <img src="/assets/{$siteData.theme}_logo_white.svg">
        </div>
        <div class="state">
            {#if data}
                <div on:click class="frame" style="{data.thumbnail ? `background-image:url(${data.thumbnail})` : ''}">
                    {#if !$screen || $screen.loading}
                        <OverlaySpinner></OverlaySpinner>
                    {/if}
                    {#if !data.thumbnail && data.title}
                    {data.title.length > 40 ? data.title.substring(0, 40) + '...' : data.title}
                    {/if}
                </div>
                <div class="details">
                    <div class="title">
                        {#if data.title}
                            {data.title.length > 40 ? data.title.substring(0, 40) + '...' : data.title}
                        {/if}
                    </div>
                    <div>
                        {#if $screen && $screen.presentation}
                            {#if $screen.loading}
                                - / -
                            {:else}
                                <ion-icon on:click={prev} name="caret-back-circle-outline"></ion-icon>
                                {$screen.slide + 1} / {$screen.slides}
                                <ion-icon on:click={next} name="caret-forward-circle-outline"></ion-icon>
                                {#if data.url}
                                    <ion-icon on:click={()=>dispatch('showWebContent')} name="globe-outline"></ion-icon>
                                {/if}
                                <ion-icon on:click={()=>open(null)} name="close-circle-outline"></ion-icon>
                            {/if}
                        {/if}
                    </div>
                </div>
            {/if}
        </div>
        <div class="right">
            <div class="link" >
                {#if $link}
                    <ion-icon name="link-outline"></ion-icon>
                {:else}
                    <ion-icon name="unlink-outline"></ion-icon>
                {/if}
                {$siteData.id}
            </div>

            {#if mode === '3d'}
                <ion-icon class="btn" on:click={()=>mode='grid'} name="grid-outline"></ion-icon>
            {:else}
                <ion-icon class="btn" on:click={()=>mode='3d'} name="cube-outline"></ion-icon>
            {/if}

            {#if $siteData.themes}
                <ion-icon class="btn"  on:click={()=>{
                    Modal.push(Settings)
                        .catch(() => {
                            /* download cancelled or failed */
                        });
                }} name="settings-outline"></ion-icon>
            {/if}
            
            {#if config.libraries.find(l => l.sites.includes($siteData.id))}
                <ion-icon class="btn" class:disabled={!$link} on:click={()=>{
                    if(!$link) return;
                    Modal.push(Download)
                        .catch(() => {
                            /* download cancelled or failed */
                        });
                }} name="cloud-download-outline"></ion-icon>
            {/if}
            <ion-icon class="btn" on:click={()=>Auth.signOut()} name="log-out-outline"></ion-icon>
        </div>
    </div>
</div>