<script>
    import { getContext, setContext, onDestroy } from 'svelte';

    import state from './store/auth';
	import page from './store/page';
    import { connected } from './lib/mqtt';

	import OverlaySpinner from './components/widget/OverlaySpinner.svelte';

    import Login from './modals/SiteLogin.svelte';

	import PageCmd from './pages/Cmd/Cmd.svelte';
    import PageModel from './pages/Model.svelte';

    const Modal = getContext('modal').context();
    onDestroy(() => Modal.close());

    let loading = true;
    let loading2 = false;
    let loadingCaption = '';

	state.subscribe(value => {
		Modal.close();
        if(!value.loading) {
			loading = false;
			if(!value.user || !value.site) {
				Modal.push(Login)
                    .catch(() => {
                        /* login cancelled or failed */
                    });
			}
		}
	});

    setContext('loader', {
        setLoading: function(state, caption) {
			loading2 = state;
            loadingCaption = caption || '';
		}
    });
</script>

<style>
    .caption {
        padding: 10px;
    }
</style>

{#if loading || loading2 || $state && !$state.loading && $state.user && $state.site && !$connected}
    <OverlaySpinner>
        <span slot="caption" class="caption">
            {loading ? 'Authenticating...' : (loadingCaption || 'Connecting...')}
        </span>
    </OverlaySpinner>
{/if}
{#if $state.user && $state.site && $connected}
    {#if $page === 'model'}
        <main>
            <PageModel></PageModel>
        </main>
    {:else if $page === 'cmd'}
        <PageCmd></PageCmd>
    {/if}
{/if}