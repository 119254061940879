<script>
    import Select from '../../external/svelte-select/src/Select.svelte';
    import { debounce } from '../../lib/debounce';
    import FilterEntry from './FilterEntry.svelte';
    import FilterSelectedEntry from './FilterSelectedEntry.svelte';

    export let filter;
    export let items = [];

    let selectedValue = null;
    const updateFilter = debounce(() => {
        if(selectedValue && selectedValue.length) {
            filter = {};
            const items = selectedValue.slice(0, 5);
            const favorite = items.find(value => value.value === 'favorite');
            if(favorite) {
                filter.favorite = true;
            }
                
            const words = items.filter(value => value.text).map(value => value.text);
            if(words && words.length) {
                filter.text = {
                    $contains: words
                };
            }
            const tags = items.filter(value => value.tag).map(value => value.tag);
            if(tags && tags.length) {
                filter.tags = {
                    $contains: tags
                }
            }
            const locations = items.filter(value => value.location).map(value => value.location);
            if(locations && locations.length) {
                filter.locations = {
                    $contains: locations
                }
            }
            const types = items.filter(value => value.type).map(value => value.type);
            if(types && types.length) {
                filter.types = {
                    $contains: types
                }
            }
        } else {
            if(!filter) return;
            filter = undefined;
        }
    }, 500);
    $: selectedValue, updateFilter();

    let filterText = '';
    $: selectedValue && (() => {
        const textFilter = selectedValue.find(item => item.value.indexOf('text:') === 0);
        if(textFilter) {
            if(!filterText || !filterText.trim()) {
                selectedValue = selectedValue.filter(item => item.value != 'text:');
                if(!selectedValue.length) selectedValue = [{value:'text:test',text:'test'},{value:'text:foo',text:'foo'}];
            } else {
                textFilter.value = `text:${filterText.trim()}`;
                textFilter.text = filterText.trim();
            }
        }
        if(selectedValue && selectedValue.length > 5) {
            selectedValue = selectedValue.slice(0, 5);
        }
        console.log(selectedValue);
    })();
    const loadOptions = async (prefix) => {
        let _items = items.filter(item => !selectedValue || !selectedValue.find(item2 => item2.value === item.value))
        return [
            {
                value: `text:`,
                text: prefix
            },
            ..._items
        ];
    };
</script>

<style>
:global(.selectContainer>div) {
    z-index: 1000 !important;
}
:global(.full .listContainer) {
    display: none
}
.filter-wrapper {
    flex-grow: 1;
}
</style>

<div class:full={selectedValue && selectedValue.length >= 5} class="filter-wrapper">
    <Select
        bind:selectedValue={selectedValue}
        bind:filterText={filterText}
        {loadOptions}
        isMulti={true}
        Item={FilterEntry}
        MultiSelection={FilterSelectedEntry}
        noOptionsMessage={""}
        itemSelectable={(item)=>item.value!=='text:'||(filterText && filterText.trim())}
        isSearchable={!(selectedValue && selectedValue.length >= 5)}
        placeholder={"Search by tags, name, summary or keywords..."}
    />
</div>