<script>
    import { getContext, onDestroy } from 'svelte';
    
    import Card from '../../components/widget/Card.svelte';
	import HUD from './HUD.svelte';
    import Model from '../../components/Model.svelte';
    import WebContent from './WebContent.svelte';
    import { screen, content, open, data } from '../../store/content';
    
    const Background = getContext('background');

    let webcontentVisible = false;
    let waitForSettle = true;
    
    let mode = 'grid';
    $: Background.setAnimated(mode !== '3d');

    let loading = false;
    function updateLoading() {
        let caption = '';
        if(!$screen || !$content || ($screen && $screen.library && $screen.library.state !== 'ready')) {
            loading = true;
            if(!$screen) {
                caption = 'Establishing link to presenter...';
            } else if($screen.library && $screen.library.state !== 'ready') {
                caption = $screen.library.state == 'loading' ? 'Updating library - ' + $screen.library.progress + '%' : 'Unpacking library...';
            } else {
                caption = 'Loading library...';
            }
        } else {
            loading = false;
        }
        getContext('loader').setLoading(loading, caption);
    }
    $: $screen, $content, updateLoading();

    const Modal = getContext('modal').context();
    onDestroy(() => Modal.close());

    function select(data) {
        open(data.presentation);
	}

    function settled() {
        // do this only once per presentation change
        // e.g. when switching between grid and 3d mode, we dont want the iframe to pop up again!
        if(!waitForSettle) return;
        webcontentVisible = true;
        waitForSettle = false;
    }

    function updateWebContent() {
        webcontentVisible = mode === 'grid';
        waitForSettle = mode !== 'grid';
    }

    $: $data, updateWebContent();

</script>

<style>
    .group {
		padding: 50px;
		display: flex;
		flex-wrap: wrap;
		justify-content: start;
        flex-direction: column;
        background-color: rgba(255,255,255,0.05);
        box-shadow: 2px 2px 4px rgba(0,0,0,0.1);
        margin: 25px 0px 25px 25px;
        border-radius: 16px;
	}
	.title {
        height: 30px;
        display: block;
        font-size: 30px;
        font-weight: 400;
        letter-spacing: 0.05em;
        line-height: 25px;
        padding: 0;
        color: white;
        text-decoration: none;
        cursor: default;
        flex-direction: column;
        max-width: 300px;
        text-align: center;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        text-shadow: 0px 0px 4px black;
        margin-bottom: 20px;
	}
	.wrapper {
		display: flex;
        flex-direction: row;
        padding-right: 25px;
        padding-bottom: 152px;
        min-height: 100%;
	}
	.scroll {
		overflow: scroll;
	}
    .context {
        width: 100%;
        height: calc(100vh - 152px);
    }
</style>

<HUD bind:mode={mode} on:showWebContent={()=> webcontentVisible = true}></HUD>
<WebContent bind:visible={webcontentVisible}></WebContent>
<main class:scroll={mode==='grid'}>
    {#if mode === 'grid'}
        <div class="wrapper">
            {#if !loading}
                {#if $content}
                    {#each $content as group}
                        <div class="group">
                            <div class="title">{group.name}</div>
                            {#each group.childs as data}
                                <Card active={$screen && $screen.presentation == data.presentation} on:click={()=>select(data)} {data}></Card>
                            {/each}
                        </div>
                    {/each}
                {/if}

            {/if}
        </div>
    {:else if mode === '3d'}
        <div class="context">
            <Model on:settled={settled}></Model>
        </div>
    {/if}
</main>