<script>
    import { makePersistentTransport } from "../../store/transport.js";
    import { onMount } from "svelte";
    import { data as siteData } from "../../store/site";
    import pods from "../../store/pods";

    const presets = [
        {
            id: "trueColor",
            name: "True color",
            description: "Based on bands 4,3,2",
            bands: ["B04", "B03", "B02"],
        },
        {
            id: "falseColor",
            name: "False color",
            description: "Based on bands 8,4,3",
            bands: ["B08", "B04", "B03"],
        },
        {
            id: "falseColorUrban",
            name: "False color (urban)",
            description: "Based on bands 12,11,4",
            bands: ["B12", "B11", "B04"],
        },
        {
            id: "ndvi",
            name: "NDVI",
            description: "Based on combination of bands (B8-B4)/(B8+B4)",
            bands: ["B05", "B06", "B07"],
        },
        {
            id: "moisture",
            name: "Moisture index",
            description:
                "Based on combination of bands (B8A - B11)/(B8A + B11)",
            bands: ["B8A", "B11"],
        },
        {
            id: "swir",
            name: "SWIR",
            description: "Based on bands 12,8A,4",
            bands: ["B12", "B8A", "B04"],
        },
        {
            id: "ndwi",
            name: "NDWI",
            description: "Based on combination of bands (B3 - B8)/(B3 + B8)",
            bands: ["B03", "B08"],
        },
        {
            id: "ndsi",
            name: "NDSI",
            description: "Based on bands 2,11,12",
            bands: ["B02", "B11", "B12"],
        },
    ];

    const bandObjects = [
        { id: "B01", name: "Band 1", path: "M360,-1 L360,66" },
        { id: "B02", name: "Band 2", path: "M390,-1 L390,66" },
        { id: "B03", name: "Band 3", path: "M420,-1 L420,66" },
        { id: "B04", name: "Band 4", path: "M450,-1 L450,66" },
        { id: "B05", name: "Band 5", path: "M480,-1 L480,66" },
        { id: "B06", name: "Band 6", path: "M510,-1 L510,66" },
        { id: "B07", name: "Band 7", path: "M540,-1 L540,66" },
        { id: "B08", name: "Band 8", path: "M570,-1 L570,66" },
        { id: "B8A", name: "Band 8A", path: "M600,-1 L600,66" },
        { id: "B09", name: "Band 9", path: "M630,-1 L630,66" },
        { id: "B10", name: "Band 10", path: "M660,-1 L660,66" },
        { id: "B11", name: "Band 11", path: "M690,-1 L690,66" },
        { id: "B12", name: "Band 12", path: "M720,-1 L720,66" },
    ];

    let presetId = presets[0].id;
    let preset = presets[0];

    let podState = 'idle';
    let podList = [];
    let url = null;
    let iframeSize = 'default';

    function updatePod() {
        const pod = podList.find(pod => podState === 'demo-api-' + pod.id);
        url = pod && pod.url && pod.url.primary || null;
        iframeSize = pod && pod.size || 'default';
    }

    let data = makePersistentTransport('pod:bigdata:ctrl', true);
    let demoData = makePersistentTransport("pod:bigdata:demo-satellite:band", true);
    onMount(() => {
        const unbindData = data.subscribe((_state) => {
            podState = _state;
            updatePod();
        });
        const unbindDemoData = demoData.subscribe((_state) => {
            presetId = _state;
            preset = presets.find((p) => p.id === presetId);
            if (!preset) {
                presetId = presets[0].id;
                preset = presets[0];
            }
        });
        const unbindPods = pods('bigdata').subscribe((_pods) => {
            podList = _pods;
            updatePod();
        });
        return () => {
            unbindData();
            unbindDemoData();   
            unbindPods();
        };
    });
</script>

<main class="{$siteData.theme}">
    {#if podState === 'idle'}
        <video src="/assets/pods/bigdata/{$siteData.theme}_idle_video.mp4" autoPlay loop muted class="video" crossorigin="anonymous" />
    {:else if podState === 'demo-satellite'}
        <svg
            viewBox="0 0 822 1238"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            class="bandLines"
        >
            <g stroke="none" fill="none" transform="scale(1)">
                <g transform="translate(0,0)">
                    <g transform="translate(0,0)">
                        {#each bandObjects as band}
                            <path
                                d={band.path}
                                id={band.id}
                                stroke="#2d3f95"
                                stroke-linecap="square"
                                stroke-width={preset.bands.indexOf(band.id) >= 0
                                    ? 4
                                    : 1}
                            />
                        {/each}
                    </g>
                </g>
            </g>
        </svg>
        <div class="pictureOverlay" />
        <div class="imgBox">
            <img
                src="/assets/pods/bigdata/{presetId}.jpeg"
                class="satelliteImg"
                alt="satellite image"
            />
        </div>
        <div class="overlay" />
        <div class="presets">
            {#each presets as preset}
                <div class="presetView">
                    <div
                        on:click={() => ($demoData = preset.id)}
                        disabled={presetId === preset.id ? null : "disabled"}
                        class="presetButton"
                    >
                        <div class="presetButtonText">{preset.id}</div>
                        <div class="presetButtonTextExplain">
                            {preset.description}
                        </div>
                        <div class="buttonImage" />
                    </div>
                </div>
            {/each}
        </div>
    {:else if url}
        <iframe scrolling="no" class={iframeSize} src={url} title="Demo Content" sandbox="allow-forms allow-same-origin allow-orientation-lock allow-pointer-lock allow-presentation allow-scripts" allow="autoplay *; geolocation *; gyroscope *; accelerometer *; microphone *; camera *; screen-wake-lock *"></iframe>
    {/if}
</main>

<style>
    main {
        margin: 0px;
        font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
        font-size: 12px;
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
        overflow: hidden;
    }
    main.cmb {
        background-image: url('/assets/background_primary_cmb.png');
    }
    main.aws {
        background-image: url('/assets/background_primary_aws.png');
    }
    main.bcg {
        background-image: url('/assets/background_primary_bcg.png');
    }

    .presets {
        position: absolute;
        left: 1440px;
        top: 120px;
    }

    .presetView {
        cursor: pointer;
    }

    .presetButton {
        top: 0px;
        height: 53.3px;
        width: 373px;
        font-family: "Suisse Int'l";
        font-size: 20px;
        font-weight: bold;
        position: relative;
        box-sizing: border-box;
        margin-bottom: 53.3px;
        background: rgba(100, 109, 212, 0.4);
    }

    .presetButton[disabled] {
        background: rgba(255, 255, 255, 0.2);
    }

    .presetButtonText {
        top: 4px;
        left: 22.5px;
        height: 30px;
        width: 373px;
        font-family: "Suisse Int'l";
        font-size: 20px;
        font-weight: bold;
        overflow: hidden;
        position: absolute;
        box-sizing: border-box;
    }

    .presetButtonText[disabled] {
        top: 14px;
        left: 22.5px;
        font-weight: normal;
    }

    .presetButtonTextExplain {
        top: 30px;
        left: 22.5px;
        height: 30px;
        width: 373px;
        font-family: "Suisse Int'l";
        font-weight: normal;
        font-size: 13px;
        overflow: hidden;
        position: absolute;
    }

    .buttonImage {
        position: relative;
        left: -9px;
        top: -9px;
        width: 391px;
        height: 71px;
        background-image: url("/assets/pods/bigdata/testButton.png");
    }

    .overlay {
        position: absolute;
        left: 204px;
        top: 217px;
        width: 1085px;
        height: 659px;
        background-image: url("/assets/pods/bigdata/overlay.png");
        background-repeat: no-repeat;
    }

    .pictureOverlay {
        position: absolute;
        left: 98px;
        top: 111px;
        width: 1297px;
        height: 871px;
        background-image: url("/assets/pods/bigdata/picture_overlay.png");
        background-repeat: no-repeat;
    }

    .imgBox {
        position: absolute;
        left: 124px;
        top: 137px;
        width: 1245px;
        height: 819px;
        overflow: hidden;
    }

    img.satelliteImg {
        position: absolute;
        width: 1456px;
        height: 819px;
    }

    .bandLines {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 1460px;
        height: 2200px;
    }
    iframe {
        border:none;
        position: absolute;
    }
    iframe.default, iframe.no-header {
        left: 60px;
        width: 1720px;
        top: 60px;
        height: 960px;
    }
    iframe.fullscreen {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }
</style>
