<script>
    import {
        onMount, onDestroy
    } from 'svelte';

    import OverlaySpinner from '../../../components/widget/OverlaySpinner.svelte';

    import '@tensorflow/tfjs-core';
    import '@tensorflow/tfjs-converter';
    import '@tensorflow/tfjs-backend-webgl';

    import * as poseDetection from '@tensorflow-models/pose-detection';

    import { makeTransport } from '../../../store/transport';

    export let transport = 'mqtt';
    export let paused = false;
    export let maxDetections = 6;

    let transportData = makeTransport('pod:cv:demo-pose:data', transport === 'mqtt');
    let canvas;
    let video;
    let started = false;
    let ready = false;
    let error;
    let nextFrame = 0;
    let destroyed = false;

    onMount(() => {
        let timeout;
        window.addEventListener('resize', () => {
            if(!canvas) return;
            canvas.style.display = 'none';
            // video size & layout changes a little later in some environments
            // => schedule a timeout
            ready = false;
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                if(destroyed) return;
                canvas.style.display = 'block';
                resize();
                ready = true;
            }, 1000);
        });
        return () => window.removeEventListener('resize', resize);
    });

    onDestroy(() => {
        destroyed = true;
        cancelAnimationFrame(nextFrame);
        if(video && video.srcObject) {
            video.srcObject.getTracks().forEach(track => track.stop());
        }
    });

    $: canvas && video && start();

    const VIDEO_WIDTH = 1280;
    const VIDEO_HEIGHT = 720;
    const KEYPOINT_THRESHOLD = 0.1;

    /**
     *
     */
    function resize() {
        if(!started) return;

        canvas.width  = Math.floor(video.videoWidth * 0.5);
        canvas.height = Math.floor(video.videoHeight * 0.5);
    }

    /**
     *
     */
    function startVideo() {
        if(destroyed) return;
        error = null;
        // acquire video feed
        if (!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia)) {
            error = 'Camera access not supported by Browser';
            return;
        }
        navigator.mediaDevices
            .getUserMedia({
                video: { 
                    facingMode: "user",
                    width: { ideal: VIDEO_WIDTH, max: VIDEO_WIDTH },
                    height: { ideal: VIDEO_WIDTH, max: VIDEO_HEIGHT }
                }
            })
            .then(stream => {
                if(destroyed) return;
                video.srcObject = stream;
                video.setAttribute("playsinline", true); // required to tell iOS safari we don't want fullscreen
                video.play().then(() => {
                    if(destroyed) return;
                    ready = true;
                    /*
                    // this needs to be triggered by a click to work..
                    if('requestFullscreen' in wrapper) {
                        wrapper.requestFullscreen();
                    }
                    */
                    nextFrame = requestAnimationFrame(processFrame);
                    resize();
                });
            })
            .catch(() => {
                error = 'No Camera available';
            });
    }

    let net;
    let context;

    const links = [
        ["neck", "nose"],
        ["left_eye", "nose"],
        ["right_eye", "nose"],
        ["left_hip", "left_shoulder"],
        ["left_elbow", "left_shoulder"],
        ["left_elbow", "left_wrist"],
        ["left_hip", "left_knee"],
        ["left_knee", "left_ankle"],
        ["right_hip", "right_shoulder"],
        ["right_elbow", "right_shoulder"],
        ["right_elbow", "right_wrist"],
        ["right_hip", "right_knee"],
        ["right_knee", "right_ankle"],
        ["left_shoulder", "right_shoulder"],
        ["left_hip", "right_hip"]
    ];
    const points = [
        "neck", "nose",
        "left_eye", "right_eye",
        "left_hip", "right_hip",
        "left_knee", "right_knee",
        "right_elbow", "left_elbow",
        "left_shoulder", "right_shoulder",
        "left_wrist", "right_wrist",
        "left_ankle", "right_ankle"
    ];

    const COLOR_PALETTE = [
        '#ffffff', '#800000', '#469990', '#e6194b', '#42d4f4', '#fabed4'
    ];

    let lastUpdate = 0;

    /**
     *
     */
    async function processFrame() {
        nextFrame = requestAnimationFrame(processFrame);
        if(paused) return;

        let segmentations = await net.estimatePoses(video);
        if(segmentations.length > maxDetections) {
            segmentations = segmentations.slice(0, maxDetections);
        }
        if(Date.now() - lastUpdate > 1000) {
            $transportData = 
                segmentations
                    .sort((a,b) => (a.id||0) - (b.id||0))
                    .map((p) => {
                        const points = p.keypoints.filter((point) => point.score >= KEYPOINT_THRESHOLD);
                        return points.map(p => {
                            return {
                                x: p.x,
                                y: p.y,
                                part: p.name
                            };
                        });
                    });
        }
        
        context.clearRect(0, 0, canvas.width, canvas.height);

            /*
        maskImage.data.fill(180);

        for(let _p = 0; _p < segmentations.length; _p++) {
            const segmentation = segmentations[_p];
            const data = segmentation.segmentation.mask.toImageData();
            for (let x = 0; x < NET_WIDTH; x++) {
                for (let y = 0; y < NET_HEIGHT; y++) {
                    const j = y * NET_WIDTH + x;
                    const v = data[j];

                    if(v) {
                        maskImage.data[j * 4 + 3] = 0;
                    }
                }
            }
        }
        context.putImageData(maskImage, 0, 0);
        */

        const xFactor = canvas.width / video.videoWidth;
        const yFactor = canvas.height / video.videoHeight;
        for(let _p = 0; _p < segmentations.length; _p++) {
            const pose = segmentations[_p];
            const map = {};
            for (let j = 0; j < pose.keypoints.length; j++) {
                const keypoint = pose.keypoints[j];
                if(keypoint.score < KEYPOINT_THRESHOLD) continue;
                map[keypoint.name] = {
                    x: keypoint.x,
                    y: keypoint.y
                };
            }

            if(map['left_shoulder'] && map['right_shoulder']) {
                map['neck'] = {
                    x: (map['left_shoulder'].x + map['right_shoulder'].x)/2,
                    y: (map['left_shoulder'].y + map['right_shoulder'].y)/2
                };
            }

            //const chestHeight = Math.abs(map["leftHip"].y - map["leftShoulder"].y);
            //const legHeight = Math.abs(map["leftAnkle"].y - map["leftHip"].y);
            // = (chestHeight < 20) || (chestHeight < legHeight * 0.5);
            const alert = (map["left_wrist"] && map["left_shoulder"] && map["left_elbow"] && (map["left_wrist"].y < map["left_shoulder"].y)) ||
                (map["right_wrist"] && map["right_shoulder"] && map["right_elbow"] && (map["right_wrist"].y < map["right_shoulder"].y));
            
            context.strokeStyle = context.fillStyle = alert ? 'orange' : 'white';
            context.lineWidth = 3.0;
        
            context.beginPath();
            for (let j = 0; j < links.length; j++) {
                const keyA = links[j][0];
                const keyB = links[j][1];
                const from = map[keyA];
                const to = map[keyB];
                if(!from || !to) continue;
                context.moveTo(from.x * xFactor, from.y * yFactor);
                context.lineTo(to.x * xFactor, to.y * yFactor);
            }
            context.stroke();

            for(let j = 0; j < points.length; j++) {
                let point = points[j];
                if(!map[point]) continue;
                point = map[point];
                context.beginPath();
                context.arc(point.x * xFactor, point.y * yFactor, 5, 0, 2*Math.PI);
                context.fill();
            }
        }
    }

    /**
     *
     */
    async function start() {
        if(started) return;
        started = true;

        /*
        net = await bodyPix.load({
            architecture: 'ResNet50',
            outputStride: 16,
            quantBytes: 2,
        });
        net = await bodySegmentation.createSegmenter(bodySegmentation.SupportedModels.BodyPix, {
            architecture: 'MobileNetV1',
            outputStride: 16,
            multiplier: 0.75,
            quantBytes: 2
        });
        */
        const detectorConfig = {
            modelType: poseDetection.movenet.modelType.MULTIPOSE_LIGHTNING,
            enableSmoothing: true,
            //multiPoseMaxDimension: 512,
            minPoseScore: 0.2,
            enableTracking: true,
            trackerType: poseDetection.TrackerType.BoundingBox
            /*trackerConfig: {
                maxTracks: 18,  // 3 times max detections of the multi-pose model.
                maxAge: 100000,
                minSimilarity: 0.01,
                trackerParams: {}
            },
            */
        };
        const model = poseDetection.SupportedModels.MoveNet;
        net = await poseDetection.createDetector(model, detectorConfig);

        // configure canvas
        context = canvas.getContext("2d");
        context.textAlign = "center";
        context.font = "20pt Arial";

        startVideo();
    }
</script>

<style>
  canvas {
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 100;
    margin: 0;
  }
  video {
      max-height: 100%;
      max-width: 100%;
      height: 100%;
  }
  video {
    background-color: black;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }
  canvas {
      width: 100%;
      height: 100%;
  }
  .content {
    width: 100%;
    position: relative;
    transform: scale(-1, 1);
    /* width is set as 100% here. any width can be specified as per requirement */
    width: 100%;
    padding-top: 56.25%;
    height: 0px;
    border-radius: 16px;
    border: 5px solid rgba(0,0,0,0.8);
    overflow: hidden;
  }
</style>

<div class="content">
    {#if !ready}
        <OverlaySpinner></OverlaySpinner>
    {/if}
    <canvas bind:this={canvas} />
    <video bind:this={video}  />
</div>
