<script>
	import CameraFeed from '../pods/cv/components/CameraFeed.svelte';
	import Detections from '../pods/cv/components/Detections.svelte';

    import BGAnimation from '../components/widget/BGAnimation.svelte';

    let pageIndex = 0;
	let countdown = 20;
	setInterval(() => {
		if(countdown === 0) {
			pageIndex = (pageIndex+1)%4;
			countdown = 20;
		}
		countdown--;
	}, 1000);

    function selectPage(index) {
		countdown = 20;
		pageIndex = index;
	}
</script>

<style>
    .split-v {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 100%;
	}
	.split-v>div {
		flex-grow: 0;
		flex-shrink: 0;
		flex-basis: 50%;
	}
	.split-v>div:first-child {
		max-height: 800px;
	}
	.split-v>div:last-child {
		max-height: 500px;
	}
	.overlay-top {
		width: 100%;
		height: 180px;
		position: absolute;
		top: 0px;
		background-color: rgba(0,0,0,0.5);
		color: white;
		font-size: 48pt;
		font-weight: bold;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}
	.overlay-bottom {
		width: 100%;
		height: 180px;
		position: absolute;
		bottom: 0px;
		background-color: rgba(0,0,0,0.5);
		color: white;
		font-size: 48pt;
		font-weight: bold;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}
	.circles {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		padding: 20px;
	}
	.circle:not(:first-child) {
		margin-left: 50px;
	}
	.circle {
		border-radius: 100%;
		width: 32px;
		height: 32px;
		background-color: gray;
	}
	.circle.active {
		background-color: white;
	}
	.countdown {
		position: absolute;
		right: 0px;
		bottom: 0px;
		color: white;
		font-size: 48pt;
		color: rgba(100,100,100,0.5);
		padding: 50px;
	}
	.qrcode {
		position: absolute;
		left: 40px;
		bottom: 40px;
		width: 100px;
		height: 100px;
	}
	.qrcode img {
		width:100%;
		height:100%;
	}
	.reachout {
		position: absolute;
		left: 160px;
		bottom: 40px;
		width: 100px;
		height: 100px;
		font-size: 10pt;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.kiosk-title {
		font-size: 40pt;
	}
	.kiosk-subtitle {
		font-size: 24pt;
	}
	.kiosk-grid {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		width: 100%;
		height: 100%;
		padding-top: 250px;
		padding-bottom: 250px;
		padding-left: 50px;
		padding-right: 50px;
		justify-content: space-around;
		align-items: center;
	}
	.kiosk-grid-header {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		width: 100%;
		padding-top: 50px;
		padding-left: 50px;
		padding-right: 50px;
		justify-content: space-around;
		align-items: center;
	}
	.info {
		margin: 10px;
		width: 100%;
		color: white;
		font-size: 40pt;
		text-align: center;
		background-color: rgba(0,0,0,0.5);
		padding: 50px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.kiosk-grid .info {
		flex-basis: 95%;
	}

	.kiosk-grid .tile {
		background-color: rgba(0,0,0,0.5);
		margin: 2.5%;
		flex-basis: 45%;
		color: white;
		font-size: 24pt;
		text-align: center;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.kiosk-grid .tile img {
		width: 100%;
	}
	.kiosk-grid .tile.icon img {
		width: 150px;
		margin: 20px;
	}
	.kiosk-grid .tile.icon {
		padding: 50px;
	}
	.cv-wrapper-1 {
        width: 100%;
        height: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 50px;
    }
	.cv-wrapper-2 {
	}
	main {
		width: 100%;
	}
</style>

<BGAnimation animated={pageIndex != 2}></BGAnimation>

<!-- Offering -->
<main style={pageIndex != 0 ? 'display:none' : ''}>
    <div class="kiosk-grid">
        <div class="info icon">
            <div>
                New <span style="color: #00be71;">proven workshop format</span> now available in the <span style="color: #00be71;">New York Immersion Center</span>,
                covering a broad range of topics.
            </div>
        </div>
        <div class="tile icon">
            <img src="/assets/topic-cloud.png" alt="Use Case" />
            Power of the Cloud
        </div>
        <div class="tile icon">
            <img src="/assets/topic-cognitive.png" alt="Use Case" />
            Cognitive Services
        </div>
        <div class="tile icon">
            <img src="/assets/topic-metaverse.png" alt="Use Case" />
            Metaverse
        </div>
        <div class="tile icon">
            <img src="/assets/topic-auto.png" alt="Use Case" />
            Future of Automation
        </div>
        <div class="tile icon">
            <img src="/assets/topic-fof.png" alt="Use Case" />
            Factory of the Future
        </div>
        <div class="tile icon">
            <img src="/assets/topic-iot.png" alt="Use Case" />
            Industrial IoT
        </div>
    </div>
</main>
<!-- Discover -->
<main style={pageIndex != 1 ? 'display:none' : ''}>
    <div class="kiosk-grid">
        <div class="info">
            <div>
                70+ digital use cases
            </div>
        </div>
        <div class="tile">
            <img src="/assets/usecase-1.png" alt="Use Case" />
            Self-Optimizing Production System
        </div>
        <div class="tile">
            <img src="/assets/usecase-2.png" alt="Use Case" />
            Optimize your Digital supply chain with AI
        </div>
        <div class="tile">
            <img src="/assets/usecase-3.png" alt="Use Case" />
            Geospatial Data Analysis for precise fertilization
        </div>
        <div class="tile">
            <img src="/assets/usecase-4.png" alt="Use Case" />
            Optimize manual workflows with NLP technology
        </div>
        <div class="tile">
            <img src="/assets/usecase-5.png" alt="Use Case" />
            Reduce food waste with computer vision
        </div>
        <div class="tile">
            <img src="/assets/usecase-6.png" alt="Use Case" />
            Next generation statistics powered by AI
        </div>
    </div>
</main>
<!-- Inspire -->
<main style="position:absolute;top:200px;{pageIndex != 2 ? 'display:none' : ''}">
    <div class="kiosk-grid-header">
        <div class="info">
			Live AI tech demos
		</div>	
	</div>
	<div class="split-v">
        <div class="cv-wrapper-1">
			<CameraFeed maxDetections={3} paused={pageIndex != 2}></CameraFeed>
        </div>
        <div class="cv-wrapper-2">
            <Detections maxDetections={3} showAlert={false} showCount={false}></Detections>
        </div>
    </div>
</main>
<!-- Co-Create -->
<main style={pageIndex != 3 ? 'display:none' : ''}>
    <div class="kiosk-grid">
        <div class="info">
            <div>
                Technology based ideation
            </div>
        </div>
        <div class="tile">
            <img src="/assets/techcard-1.png" alt="Use Case" />
            Internet of Things
        </div>
        <div class="tile">
            <img src="/assets/techcard-2.png" alt="Use Case" />
            Computer Vision
        </div>
        <div class="tile">
            <img src="/assets/techcard-3.png" alt="Use Case" />
            Open Data
        </div>
        <div class="tile">
            <img src="/assets/techcard-4.png" alt="Use Case" />
            NLP
        </div>
        <div class="tile">
            <img src="/assets/techcard-5.png" alt="Use Case" />
            Robotics
        </div>
        <div class="tile">
            <img src="/assets/techcard-6.png" alt="Use Case" />
            Data & Analytics
        </div>
    </div>
</main>
<!-- Hud -->
<div class="overlay-top">
    <div class="kiosk-title">
        Technology Innovation Workshop
    </div>
    <div class="kiosk-subtitle">
        Bring disruptive technologies to traditional businesses
    </div>
</div>
<div class="overlay-bottom">
    <div>
        {#if pageIndex === 0}
            Offering
        {:else if pageIndex === 1}
            Discover
        {:else if pageIndex === 2}
            Inspire
        {:else}
            Co-create
        {/if}
    </div>
    <div class="circles">
        <div on:click={()=>selectPage(0)} class="circle" class:active={pageIndex===0}></div>
        <div on:click={()=>selectPage(1)} class="circle" class:active={pageIndex===1}></div>
        <div on:click={()=>selectPage(2)} class="circle" class:active={pageIndex===2}></div>
        <div on:click={()=>selectPage(3)} class="circle" class:active={pageIndex===3}></div>
    </div>
    <div class="countdown">
        {countdown}
    </div>
    <div class="qrcode">
        <img src="/assets/qrcode.png" alt="qrcode" />
    </div>
    <div class="reachout">
        <div>
            Reach out<br />to Sasha Engel<br />for more<br />information
        </div>
    </div>
</div>