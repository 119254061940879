<script>
    import { screen, rawContent, open } from '../store/content';
    import { createEventDispatcher } from 'svelte';
    import config from '../../config';

    const dispatch = createEventDispatcher();

    export let showNavigation = true;
    export let showPresentations = true;

    /**
     * the id of the model used for context / navigation
     * @type {string}
     */
    let contextModelId = 'innovation';
    /**
     * the id of the currently desired model
     * can be different from context model if showing presentation content
     * @type {string}
     */
    let modelId = null;
    /**
     * the id of the currently loaded model
     * @type {string}
     */
    let loadedModelId = null;
    /**
     * current model mode:
     * - context: for navigating between presentations
     * - presentation: augmented storytelling controlled by a single presentation
     * @type {string}
     */
    let modelMode = 'context';
    /**
     * do not automatically switch to presentation mode
     * @type {boolean}
     */
    let skipPresentation = false;
    /**
     * current active path
     * @type {string}
     */
    let activePath = '';
    /**
     * current active presentation
     * @type {string}
     */
    let activePresentation = '';

    let modeTimeout;
    let loading = false;
    let iframe;
    let origin = document.location.hostname === 'localhost' ? 
        'http://localhost:5002' :
        (new URL(`https://${config.model}`)).origin;
    
    $: $screen && updatePresentation();
    $: $rawContent && updatePresentation(true);
    $: iframe && loadModel();

    /**
     * initialize the model
     */
    function initModel() {
        if(modelMode === 'context') {
            selectPath(activePath);
        } else {
            iframe.contentWindow.postMessage({
                event: 'content',
                data: {
                    content: {
                        actions: lookupPath(activePath).actions
                    },
                    slide: $screen && $screen.slide || 0,
                    buttons: [],
                    items: null
                }
            }, origin);
        }
    }

    /**
     * triggered to (re)load the model
     */
    function loadModel() {
        // if not ready to load yet => return
        // this handles iframe becoming ready before subscription data
        if(!iframe || !$screen || !$rawContent || !modelId) return;

        // determine if content can be changed without a reload?
        if(!loading && modelId === loadedModelId) {
            initModel();
            return;
        }

        // if already loading stop here
        // once iframe is loaded it can still activate the right model, even if it changed/changes in the meantime
        if(loading) return;

        loading = true;
        loadedModelId = null;
        iframe.onload = () => {
            loading = false;
            loadedModelId = modelId;

            iframe.contentWindow.postMessage({
                event: 'init',
                data: {
                    id: modelId,
                    resources: {
                        screen: '/assets/'
                    }
                }
            }, origin);

            initModel();
        };
        iframe.src = document.location.hostname === 'localhost' ? 'http://localhost:5002' : `https://model.virtual-xperience.io`;
    }

    /**
     * triggered when screen state (open presentation, active slide) or library (available presentations) changes
     * @param {boolean} libraryUpdated
     */
    function updatePresentation(libraryUpdated = false) {
        const presentation = $screen && $screen.presentation;
        const res = getPresentation(presentation || null);
        const path = res && res.path || '';
    
        // when the library changes determine which model to use for context
        // this might change everytime when library updates..
        if(libraryUpdated) {
            contextModelId = $rawContent.meta && $rawContent.meta.context && $rawContent.meta.context[0] && $rawContent.meta.context[0].model || contextModelId;
        }
        skipPresentation = false;

        // if focused presentation (determined by path) changes always switch to context mode 
        // when presentation was not found or library changes also switch to context mode
        // path might be identical in those cases but we still reload/refresh to be safe
        // special case: if focusing root the presentation can be shown or not shown, model state will be the same.. but UI changes!
        if(activePath !== path || (!path && activePresentation !== presentation) || !res || libraryUpdated) {
            selectPath(path);
        // otherwise if in presentation mode and model already loaded just update position
        } else if(loadedModelId && modelMode === 'presentation') {
            iframe.contentWindow.postMessage({
                event: 'slide',
                data: {
                    slide: $screen && $screen.slide || 0
                }
            }, origin);
        }

        // check if model still needs to be initialized if screen state or library just arrived
        if(!loading && !loadedModelId) {
            loadModel();
        }
    }

    /**
     * @param {Object} data
     */
    function onMessage(data) { 
        switch(data.data.type){  
            case "copy-to-clipboard": 
                navigator.clipboard.writeText(data.data.toCopy).catch(()=>{/* ignore error */});
                break;
            case "ui":
                const object = lookupPath(data.data.id);
                open(object && object.presentation || null);
                break;
        }
    }

    /**
     * get library path for presentation file path as reported by screen
     * @param {string} presentation
     * @return {?{path:string, node:Object}}
     */
     function getPresentation(presentation) {
        if(!$rawContent || !presentation) return null;
        function visit(node, path) {
            if(node.presentation && node.presentation === presentation) {
                return {node, path};
            }
            if(node.childs) {
                for(let i = 0, n = node.childs.length; i < n; i++) {
                    const res = visit(node.childs[i], (path ? path + '/' : path) + i );
                    if(res) return res;
                }
            }
            return null;
        }
    
        return visit($rawContent, '');
    }

    /**
     * get presentation object by path
     * @param {string} path
     */
    function lookupPath(path) {
        if(!$rawContent) return null;
        const parts = path.split('/');
        let root = $rawContent;
        for(let i = 0, n = parts.length; i < n && root; i++) {
            const index = parts[i];
            if(!index) continue;
            root = root.childs && root.childs[index] || null;
        }
        return root;
    }

    /**
     * returns the current context actions for the specified library path
     * by returning the first defined actions from the path upwards
     * meaning if the path has no defined state, try it's parent, etc..
     * @param {string} path
     */
    function getActions(path) {
        // walk up the tree to find a context
        const parts = path.split('/');
        let context = null;
        for(let i = parts.length; i >= 0 && (!context || !context.actions || !context.actions.length); i--) {
            const rootPath = parts.slice(0, i).join('/');
            const rootObject = lookupPath(rootPath);
            context = rootObject && rootObject.meta && rootObject.meta.context && rootObject.meta.context.find(context => context.model === contextModelId) || null;
        }
        return context && context.actions || [];
    }

    /**
     * check if the specified library path is reachable via UI
     * a path is considered reachable if itself and all of its parents have a context with position defined for the current model
     * @param {string} path
     * @return {boolean}
     */
     function isReachable(path) {
        // walk up the tree to find a context
        const parts = path.split('/');
        let context = null;
        for(let i = parts.length; i >= 0; i--) {
            const rootPath = parts.slice(0, i).join('/');
            const rootObject = lookupPath(rootPath);
            context = rootObject && rootObject.meta && rootObject.meta.context && rootObject.meta.context.find(context => context.model === contextModelId) || null;
            if(!context || !context.position) return false;
        }
        return true;
    }

    /**
     * display the specified library path
     * NOTE: this does not open the respective presentation!
     * @param {string} path
     * @param {boolean} setSkipPresentation do not automatically active presentation mode
     */
    function selectPath(path, setSkipPresentation = false) {
        // we do not check if the active path is identical, because there are cases where we need to reload anyway
        // e.g. when the content library changes.. that can only be determined outside, because we do not have a library id/hash at the moment
        // => this method should only be called when a refresh is really needed
        activePath = path || '';
        if(setSkipPresentation) skipPresentation = true;

        const items = [];
        const object = lookupPath(activePath);
        
        if(modelMode !== 'context' || modelId !== contextModelId) {
            modelMode = 'context';
            modelId = contextModelId;
            loadModel();
            return;
        }

        // if model is not loaded yet return
        if(loadedModelId !== contextModelId) return;

        if(showNavigation && isReachable(activePath)) {
            // get object context; will never be null here because of reachable check
            // context & position are defined!
            let context = object.meta && object.meta.context && object.meta.context.find(context => context.model === contextModelId) || null;

            // folder
            if(object.type === 'folder') {
                if(activePath || (!activePath && !$screen.presentation)) {
                    items.push({
                        title: object.meta && object.meta.name || object.name,
                        position: context.position,
                        type: !activePath ? 'link': 'close',
                        id: activePath.split('/').slice(0, -1).join('/')
                    });
                }
                for(let i = 0, n = object.childs && object.childs.length || 0; i < n; i++) {
                    const child = object.childs[i];
                    // only create navigation entry if context & position are defined
                    const context = child.meta && child.meta.context && child.meta.context.find(context => context.model === contextModelId) || null;
                    if(context && context.position) {
                        items.push({
                            title: child.meta && child.meta.name || child.name,
                            position: context.position,
                            type: 'link',
                            id: (activePath ? activePath + '/' : '') + i
                        }); 
                    }
                }
            // presentation
            } else {
                items.push({
                    title: object.meta ? object.meta.name : object.name,
                    position: context && context.position,
                    type: 'close',
                    id: activePath.split('/').slice(0, -1).join('/')
                });
            }
        }

        iframe.contentWindow.postMessage({
            event: 'content',
            data: {
                content: {
                    actions: {
                        "0": getActions(activePath)
                    }
                },
                slide: 0,
                buttons: [],
                items
            }
        }, origin);

        // automatically switch to showing presentation context after 5s
        // @TODO: determine runtime of context actions as requested above?
        if(modeTimeout) {
            clearTimeout(modeTimeout);
            modeTimeout = null;
        }
        if(showPresentations) {
            if(!skipPresentation && object && object.meta && object.meta.model) {
                modeTimeout = setTimeout(() => {
                    dispatch('settled');
                    modelMode = 'presentation';
                    modelId = object.meta.model;
                    loadModel();
                }, 5000);
            } else if(skipPresentation) {
                skipPresentation = false;
            }
        }
        if(!modeTimeout) {
            modeTimeout = setTimeout(() => {
                dispatch('settled');
            }, 5000);
        }
    }
</script>

<style>
    iframe {
        width: 100%;
        height: 100%;
        border:none;
    }
    .button {
        color: var(--theme-main);
        flex-grow: 0;
        padding: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 48px;
        height: 48px;
        transition: transform .2s ease-in-out;
        cursor: pointer;
    }
    .button.close {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 101;
    }
    .box {
        background-color: rgba(255, 255, 255,0.85);
        box-shadow: 0 2px 40px 0 rgb(0 0 0 / 10%);
        border: 1px solid #dedede;
        border-radius: 4px;
    }
</style>

<iframe bind:this={iframe} scrolling="no" title="3D Model" sandbox="allow-forms allow-same-origin allow-orientation-lock allow-pointer-lock allow-presentation allow-scripts" allow="autoplay *; geolocation *; gyroscope *; accelerometer *; microphone *; camera *; screen-wake-lock *"></iframe>
{#if showNavigation && modelMode === 'presentation'}
    <div title="Back to overview" class="button close box" on:click={()=>selectPath(activePath, true)}>
        <ion-icon name="close-outline" size="large"></ion-icon>
    </div>
{/if}
<svelte:window on:message={onMessage} />