<script>
    import Person from './Person.svelte';
    import { makeTransport } from '../../../store/transport';

    export let transport = 'mqtt';
    export let showCount = true;
    export let showAlert = true;
    export let maxDetections = 6;
    export let row = 3;

    let transportData = makeTransport('pod:cv:demo-pose:data', transport === 'mqtt');

    let alert = false;
    let numPersons = 0;
    
    let persons = Array(maxDetections).fill([]);
    transportData.subscribe(data => {
        alert = false;
        numPersons = 0;
        if(!data) return;
        for(let i = 0,n = Math.min(maxDetections, data.length); i < n; i++) {
            numPersons++;
            const points = data[i];
            const map = {};
            let minX = Infinity, maxX = -Infinity;
            let minY = Infinity, maxY = -Infinity;
            for(let j = 0, k = points.length; j < k; j++) {
                const point = points[j];
                if(point.x < minX) minX = point.x;
                if(point.x > maxX) maxX = point.x;
                if(point.y < minY) minY = point.y;
                if(point.y > maxY) maxY = point.y;
            }
            const width = maxX - minX, height = maxY - minY;
            const figure = [];
            const scale = height * (150/width) > 200 ? (200/height) : (150/width);
            for(let j = 0, k = points.length; j < k; j++) {
                const point = points[j];
                const scaledPoint = {
                    x: (point.x - minX) * scale + (150-(width*scale))/2,
                    y: (point.y - minY) * scale + (200-(height*scale))/2,
                    part: point.part
                };
                map[point.part] = scaledPoint;
                figure.push(scaledPoint);
            }

            if(map['left_shoulder'] && map['right_shoulder']) {
                figure.push({
                    x: (map['left_shoulder'].x + map['right_shoulder'].x)/2,
                    y: (map['left_shoulder'].y + map['right_shoulder'].y)/2,
                    part: 'neck'
                });
            }

            //const chestHeight = Math.abs(map["leftHip"].y - map["leftShoulder"].y);
            //const legHeight = Math.abs(map["leftAnkle"].y - map["leftHip"].y);
            figure.alert = (map["left_wrist"] && map["left_shoulder"] && map["left_elbow"] && (map["left_wrist"].y < map["left_shoulder"].y)) ||
                (map["right_wrist"] && map["right_shoulder"] && map["left_elbow"] && (map["right_wrist"].y < map["right_shoulder"].y));

            if(figure.alert) alert = true;
            
            persons[i] = figure;
        }
        for(let i = data.length; i < maxDetections; i++) {
            persons[i] = [];
        }
        persons = persons;
    });
</script>

<style>
    .grid {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 60px;
        width: 100%;
        gap: 10px;
    }
    .status {
        flex-basis: 100%;
    }
    .status>div {
        background-color: rgba(0,0,0,0.4);
        color: white;
        font-weight: bold;
        font-size: 40px;
        text-align: center;
        width: 100%;
        padding: 10px;
    }
    .wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
    }
    .alert {
        background-color: rgba(255, 165, 0, 0.5) !important;
    }
</style>

<div class="wrapper">
    <div class="grid">
        {#if showCount}
            <div class="status">
                <div>
                    {numPersons} {numPersons===1?'person':'persons'} detected
                </div>
            </div>
        {/if}

        {#each persons as person,index}
            <Person index={index} width="calc({(100/row)|0}% - {((row-1)*10)/row}px)" data={person}></Person>
        {/each}

        {#if showAlert}
            <div class="status">
                <div class:alert={alert}>
                    {#if alert}
                        Alert
                    {:else}
                        OK
                    {/if}
                </div>
            </div>
        {/if}
    </div>
</div>