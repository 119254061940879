<script>
    import { makePersistentTransport } from '../../store/transport.js';
    import Card from '../../components/widget/Card.svelte';
    import { data as siteData } from "../../store/site";
    import pods from '../../store/pods';

    const cvPods = pods('cv');
    let data = makePersistentTransport('pod:cv:ctrl', true);

    function validatePod() {
        // data will initially be undefined; wait until it is populated, to not overwrite mqtt topic while still waiting for first response from server
        if($data === undefined) return;
        if(!$cvPods.find(pod => $data === 'demo-api-' + pod.id)) {
            $data = 'idle';
        }
    }
    $: $cvPods && validatePod();

    // update demo
    let url = null;
    let iframeSize = 'default';

    let initTimeout = setTimeout(() => $data = 'idle', 5000);
    function updatePod() {
        if(initTimeout) {
            initTimeout = null;
            clearTimeout(initTimeout);
        }
        const pod = $cvPods.find(pod => $data === 'demo-api-' + pod.id);
        url = pod && pod.url && pod.url.control || null;
        iframeSize = pod && pod.size || 'default';
    }
    $: $data && updatePod();

    // revert to idle after 30 minutes
    let idleTimeout = null;
    function detectIdle() {
        console.log('scheduling idle timeout');
        if(idleTimeout) clearTimeout(idleTimeout);
        idleTimeout = setTimeout(() => {
            $data = 'idle';
        }, 30 * 60 * 1000);
    }
    $: $data && detectIdle();
</script>

<style>
    .wrapper {
        width: 100%;
        height: 100%;
        background-size: cover;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        justify-content: center;
        padding: 100px;
    }
    .wrapper.cmb {
        background-image: url('/assets/background_primary_cmb.png');
    }
    .wrapper.aws {
        background-image: url('/assets/background_primary_aws.png');
    }
    .wrapper.bcg {
        background-image: url('/assets/background_primary_bcg.png');
    }
    .idle {
        text-align: center;
        color: gray;
        position: absolute;
        bottom: 0;
        font-size: 18pt;
        width: 100%;
        padding: 50px;
    }
    iframe {
        border:none;
        position: absolute;
    }
    iframe.default, iframe.no-header {
        left: 60px;
        width: 1720px;
        top: 60px;
        height: 960px;
    }
    iframe.fullscreen {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }
    .backBtn {
        border-radius: 16px;
        position: absolute;
        top: -16px;
        left: 870px;
        background-color: rgba(0,0,0,0.5);
        color: white;
        width: 180px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18pt;
        padding-top: 16px;
        border: 1px solid white;
    }
</style>
  
<div class="wrapper {$siteData.theme}">
    {#if url}
        <iframe scrolling="no" class={iframeSize} src={url} title="Demo Content" sandbox="allow-forms allow-same-origin allow-orientation-lock allow-pointer-lock allow-presentation allow-scripts" allow="autoplay *; geolocation *; gyroscope *; accelerometer *; microphone *; camera *; screen-wake-lock *"></iframe>
    {:else}
        <Card active={$data==='demo-pose'} on:click={()=>$data='demo-pose'} data={{title:'Pose Detection', thumbnailURL:'/assets/pods/cv/card_demo-pose.png'}}></Card>
        {#each $cvPods as pod}
            <Card active={$data==='demo-api-'+pod.id} on:click={()=>$data='demo-api-'+pod.id} data={{title:pod.description, thumbnailURL:pod.thumbnail||null}}></Card>
        {/each}
    {/if}
</div>
{#if !url}
    <div class="idle" on:click={()=>$data='idle'}>Play Idle Loop</div>
{:else}
    <div class="backBtn" on:click={()=>$data='idle'}>
        Close demo
    </div>
{/if}